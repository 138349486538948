import { user } from '../_constants';
const initialState = {
userinput: 'No user selected'
};
export default function userreducer(state = initialState, action)
{
switch(action.type) {
case "user":
return { ...state, userinput: action.payload };
      console.log("user", state.user);
default:
return state;
}
}
