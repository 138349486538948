import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import {db} from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import SelectedReci from './ReciSelected.js'
import { Routes, Route, Link,useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import Quantity_Icon from './images/quantity.png'
import Units_Icon from './images/Units.png'
import Ingredient_Icon from './images/ingredient.png'
import Nutrition_Icon from './images/nutrition.png'
import ProfileImage from './images/profile.png'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from 'react-player'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { connect, useDispatch, useSelector } from 'react-redux';

import store from './_store/configurestore.js';

import {changepost} from './_actions/postActions'
import {changeprofile} from './_actions/profileActions'
import { query, collection, where,onSnapshot } from "firebase/firestore";
function TopReciSelected(props) {
  const params = useParams();
  const [info , setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [postreducer, setpostreducer] = useState("test");

  const dispatch = useDispatch();

  const state = useSelector((state)=> state);

  console.log(state)

  useEffect(()=> {
    const state = store.getState();
    setpostreducer(state.postreducer)
  })

  const opts = {
      height: '600',
      width: '90%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
      },
    };

    //const {postreducer} = this.props;

    const divStyle = {};

        const ColoredLine = ({ color }) => (
          <hr
            style={{
              color,
              backgroundColor: color,
              height: .5
            }}
          />
        );

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_POSTS).where("post_id", "==", state.postReducers.postinput).limit(1).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);
 console.log('state.postReducers.postinpu',state.postReducers.postinpu);
  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("post_id", "==", state.postReducers.postinput))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
   }, []);

  return (
    <div style={divStyle} className='cookboomk_main'>
  {Recis?.map(({id, data}) => (
    <Grid key={id}>
      <Paper className='cookbook_selecttoprec'>
      <Row className="mx-0">
        <Col>
          <p><b>{data.Cuisine}</b>: {data.Title}</p>
          <p><b>Author(s) ({data.author.length}):</b>
          <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}>{data.author ? data.author[0]?.Name: ""} </Link>
          {/* {data.author.map((name,index) => <a key={name} href={data.author[index].profile_URL}>{data.author[index].Name}</a>)} */}
          </p>
          <p><img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20"/> Serving Size: {data.serving_size} <img src={Min_Cook_Icon} alt="Min Cook" width="20" height="20"/> Min Cook: {data.min_cook} min</p>
          <ReactPlayer url={data.YouTube_URL} width="100%" controls="true" style={{backgroundColor: '#000000'}} />
        </Col>
        <Row className="mx-0 profile_list">
          <Link style={{textDecoration: 'none'}} onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}><Button as={Col} variant="primary"><img src={data.profile_pic ? data.profile_pic : ProfileImage} alt="User" width="30" height="30"/></Button></Link>
          <Link style={{textDecoration: 'none'}} to={`/SelectedReci/${id}`}><Button onClick={() => dispatch(changepost(data.post_id))} as={Col} variant="secondary" className="mx-2">
          <img src={Cook_Icon} alt="Cook this Reci" width="35" height="35"/>
          </Button></Link>
          <Button as={Col} variant="primary"><img src={Nutrition_Icon} alt="See Reci Nutrition Label (Sugars, Carbs, Energy, Fats, and Proteins)" width="35" height="35"/></Button>
          {/* <a href={data.Website_URL? data.Website_URL : data.YouTube_URL}><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30"/> </Button></a> */}
          {
            data.Website_URL && <a href={data.Website_URL} target="_blank"><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button></a>
          }
        </Row>
        <Row className="mx-0 social_list">
          <EmailShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><EmailIcon size={32} round={true} /></EmailShareButton>
          <FacebookShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><FacebookMessengerIcon size={32} round={true} /></FacebookShareButton>
          <LinkedinShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
          <PinterestShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} media={data.Website_URL? data.Website_URL : data.YouTube_URL}><PinterestIcon size={32} round={true} /></PinterestShareButton>
          <RedditShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><RedditIcon size={32} round={true} /></RedditShareButton>
          <TumblrShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
          <TwitterShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
          <WhatsappShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
        </Row>
      </Row>

      <h4> Method </h4>
      <div width= "80%">
      {data.Reci.Steps.map((item) =>
      <p key={item}>{item}</p>
      )}
      </div>
<h4> {data.Reci.Ingredients.length} Ingredients</h4>

      {data.Reci.Ingredients.map((name,index) => <p key={name}><img src={Ingredient_Icon} alt="Ingredient" width="30" height="30"/> <b>Ingredient:</b> {data.Reci.Ingredients[index].Ingredient} <img src={Units_Icon} alt="Amount" width="25" height="25"/>  <b>Amount:</b> {data.Reci.Ingredients[index].Amount} {data.Reci.Ingredients[index].Units}
      <div>
      <ColoredLine color="red"/>
      </div>
      </p>
    )}

      </Paper>
    </Grid>
  ))}

    </div>
  );
}

const mapStateToProps = (state) => {
  const { postreducer } = state
  return {
    postreducer
  }
}

export default connect(mapStateToProps)(TopReciSelected);
