export function selectedcookbook(cookbookinput) {
   return {
      type: 'SELECTED_COOKBOOK',
      payload: cookbookinput
   }
}
export function selectedcookbook1(cookbookinput1) {
   return {
      type: 'SELECTED_COOKBOOK1',
      payload: cookbookinput1
   }
}
export function addtocookbook() {
   return {
      type: 'ADD_TO_COOKBOOK'
   }
}
export function removefrombcookbook() {
   return { type: 'REMOVE_FROM_COOKBOOK' }
}

export function resetfromcookbook() {
   return { type: 'CLEAR_COOKBOOK' }
}
