import { post } from '../_constants/index.js';
const initialState = {
postinput: '4'
};
export default function postreducer(state = initialState, action)
{
switch(action.type) {
case "post":
return { ...state, postinput: action.payload };
      console.log("post", state.post);
default:
return state;
}
}
