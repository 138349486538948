
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { addCalanderAccess, insertEventData } from '../firebase';


var CLIENT_ID = "808017621946-47ote1iu6jr2k7h9fo661kvfaeggkd1d.apps.googleusercontent.com"
var API_KEY = "AIzaSyDmDmVeub2ypToV2NdqsJpFomAeblgevwM"
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.events"
var CLIENt_SECRET = "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"
var CALENDAR_ID = 'primary'
var CALENDAR_BASE_URI = "https://www.googleapis.com/calendar/v3"
let tokenExpirationTime = null;
let USER_UUID = null;

export const getGoogleCalendarAccess = (user_id) => {
    if (user_id) {
        USER_UUID = user_id
        localStorage.setItem("userAuthId", user_id)
        const GOOGLE_ACCESS = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${process.env.REACT_APP_BASE_URL}/calender_callback&prompt=consent&response_type=code&client_id=${CLIENT_ID}&scope=${SCOPES}&access_type=offline&include_granted_scopes=true`;
        window.location.href = GOOGLE_ACCESS;
    }
}

export const getAuthRefreshToken = (user_id, codeParam) => {


    const tokenUri = "https://oauth2.googleapis.com/token";

    return axios.post(tokenUri, {
        code: codeParam,
        grant_type: 'authorization_code',
        client_id: CLIENT_ID,
        client_secret: CLIENt_SECRET,
        redirect_uri: process.env.REACT_APP_BASE_URL + '/calender_callback'

    }).then(res => {
        let data = { id: user_id ?? USER_UUID, accessData: res.data };
        return addCalanderAccess(data).then(res => {
            return res.message;
        }).catch(err => {
            return err;
        })
    }).catch(err => {
        console.log(err, "err")
        return err
    })

}

export const setToken = (access, access_token, expiresIn) => {
    const expirationTime = new Date().getTime() + expiresIn * 1000; // Convert expiresIn to milliseconds
    sessionStorage.setItem(`token_${access.email}`, access_token);
    sessionStorage.setItem('tokenExpirationTime', expirationTime);
    tokenExpirationTime = expirationTime;
};

export const getToken = (access) => {
    console.log('access', access)
    const access_token = sessionStorage.getItem(`token_${access?.email}`);
    const expirationTime = sessionStorage.getItem('tokenExpirationTime');

    if (!access_token || !expirationTime || expirationTime < new Date().getTime()) {
        // Token is expired or not set
        return fetchAccessToken(access); // Fetch a new token
    }

    return access_token;
};

const fetchAccessToken = async (access) => {
    let data = access?.calendarAccessData;
    try {
        const response = await axios.post("https://oauth2.googleapis.com/token", {
            refresh_token: data?.refresh_token,
            grant_type: 'refresh_token',
            client_id: CLIENT_ID,
            client_secret: CLIENt_SECRET,
        });
        console.log(response, "response")
        const { access_token, expires_in } = response.data;
        setToken(access, access_token, expires_in);
        return access_token;
    } catch (error) {
        console.error('Failed to fetch new token:', error);
        throw error;
    }
};



export const getEvents = async (access) => {

    const token = await getToken(access);

    return await axios.get(`${CALENDAR_BASE_URI}/calendars/${CALENDAR_ID}/events?key=${API_KEY}`, {
        headers: {
            Authorization: `Bearer ${token}` // Include the Bearer token in the Authorization header
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err, "err");
            throw err;
        });

}

export const insertEvent = async (data) => {
    console.log('data.vendor', data.vendor)
    const token = await getToken(data.vendor);

    return await axios.post(`${CALENDAR_BASE_URI}/calendars/${CALENDAR_ID}/events?key=${API_KEY}&conferenceDataVersion=${1}`, data.event, {
        headers: {
            Authorization: `Bearer ${token}` // Include the Bearer token in the Authorization header
        }
    })
        .then(async function (res) {

            // const payload = {event: res.data, user_id: data.user_id, vendor_id: data.vendor.id, payment_id: data.paymentId}

            const response = await insertEventData(data.paymentId, res.data);
            return response;

        })
        .catch(err => {
            console.log(err, "err");
            throw err;
            return 0;
        });

}




