import African from '../images/african.png'
import Afghan from '../images/Afghan.png'
import Alcohol from '../images/Alcohol.png'
import American from '../images/american.png'
import Japanese from '../images/japanese.png'
import Chinese from '../images/Chinese.png'
import Greek from '../images/greek.png'
import French from '../images/french.png'
import Jamaican from '../images/jamaican.png'
import Gujurathi from '../images/gujurathi.png'
import Pescetarian from '../images/pescetarian.png'
import Spanish from '../images/spanish.png'
import South_Indian from '../images/south_indian.png'
import Thai from '../images/Thai.png'
import Indian from '../images/Indian.png'
import BBQ from '../images/BBQ.png'
import Vegan from '../images/Vegan.png'
import Vegetarian from '../images/Vegetarian.png'
import Vietnamese from '../images/Vietnamese.png'
import Mexican from '../images/Mexican.png'
import MiddleEastern from '../images/middle_eastern.png'
import Korean from '../images/Korean_Food.png'
import Drinks from '../images/Drinks.png'
import Italian from '../images/Italian.png'

export const  reciCategories = [
    {
      catlabel: "African",
      catlink: "African",
      catimg: African,
    },
    {
      catlabel: "Afghan",
      catlink: "Afghan",
      catimg: Afghan,
    },

    {
      catlabel: "American",
      catlink: "American",
      catimg: American,
    },
    {
      catlabel: "BBQ",
      catlink: "BBQ",
      catimg: BBQ,
    },
    {
      catlabel: "Chinese",
      catlink: "Chinese",
      catimg: Chinese,
    },

    {
      catlabel: "French",
      catlink: "French",
      catimg: French,
    },
    {
      catlabel: "Gujarati",
      catlink: "Gujarati",
      catimg: Gujurathi,
    },
    {
      catlabel: "Greek",
      catlink: "Greek",
      catimg: Greek,
    },
    {
      catlabel: "Jamaican",
      catlink: "Jamaican",
      catimg: Jamaican,
    },
    {
      catlabel: "Japanese",
      catlink: "Japanese",
      catimg: Japanese,
    },
    {
      catlabel: "Indian",
      catlink: "Indian",
      catimg: Indian,
    },
    {
      catlabel: "Italian",
      catlink: "Italian",
      catimg: Italian,
    },
    {
      catlabel: "Korean",
      catlink: "Korean",
      catimg: Korean,
    },
    {
      catlabel: "Mexican",
      catlink: "Mexican",
      catimg: Mexican,
    },
    {
      catlabel: "Middle Eastern",
      catlink: "MiddleEastern",
      catimg: MiddleEastern,
    },
    {
      catlabel: "Pescetarian",
      catlink: "Pescetarian",
      catimg: Pescetarian,
    },
    {
      catlabel: "South Indian",
      catlink: "SouthIndian",
      catimg: South_Indian,
    },
    {
      catlabel: "Spanish",
      catlink: "Spanish",
      catimg: Spanish,
    },
    {
      catlabel: "Thai",
      catlink: "Thai",
      catimg: Thai,
    },
    {
      catlabel: "Vegan",
      catlink: "Vegan",
      catimg: Vegan,
    },
    {
      catlabel: "Vegetarian",
      catlink: "Vegetarian",
      catimg: Vegetarian,
    },
    {
      catlabel: "Vietnamese",
      catlink: "Vietnamese",
      catimg: Vietnamese,
    },
    {
      catlabel: "Drinks",
      catlink: "Drinks",
      catimg: Drinks,
    },
    {
      catlabel: "Alcohol",
      catlink: "Alcohol",
      catimg: Alcohol,
    }
  ];

export const user_types = [
  {
    label: 'User',
    value: 'User'
  },
  {
    label: 'Business',
    value: 'Business'
  },
  {
    label: 'Restaurant',
    value: 'Restaurant'
  },
  {
    label: 'Chef',
    value: 'Chef'
  }

]

export const timeSlotsArray = [
  '0:00', '0:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30',
  '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30',
  '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
  '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
  '20:00', '20:30', '21:00',
  // '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'
];

export const credentials = {
  "web": {
      "client_id": "808017621946-47ote1iu6jr2k7h9fo661kvfaeggkd1d.apps.googleusercontent.com",
      "project_id": "reci-dev-4ff3f",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_secret": "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",
      "redirect_uris": [
          "https://reci-dev-4ff3f.firebaseapp.com",
          "https://reci-dev-4ff3f.web.app"
      ],
      "javascript_origins": [
          "https://reci-dev-4ff3f.firebaseapp.com",
          "https://reci-dev-4ff3f.web.app"
      ]
  }
}

export const generateRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 8;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
