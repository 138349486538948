import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';

import { Row, Col, Card, InputGroup, Form, Button, Modal, ProgressBar } from 'react-bootstrap'

import moment from 'moment';
import { getProductById } from '../../firebase';



const EventDetails = ({ eventData, show, onHide }) => {

    const [product, setProduct] = useState();
    useEffect(() => {
        console.log(eventData, "eventData")

        if (eventData?.product_id) {
            getProductById(eventData.product_id).then(res => {
                console.log(res, "product")
                setProduct(res)
            }).catch(err => {
                console.log(err, "err")
            });
        }
    }, [show])



    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Event Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}><Form.Label> Event Name </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{eventData?.event?.summary} </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Event Description </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{eventData?.event?.description} </Col>
                    </Row>

                    <Row>
                        <Col md={4}><Form.Label> Chef Email </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{eventData?.event?.organizer.email} </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Meet Link </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span><a href={eventData?.event?.hangoutLink} target="_blank">Google Meet Link</a>  </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Product Name </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{product?.name} </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Product Description </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{product?.description} </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Date & Time</Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{moment(eventData?.event?.start.dateTime).format('dddd, MMMM DD, YYYY')},
                            {moment(eventData?.event?.start.dateTime).format('h:mmA')} - {moment(eventData?.event?.end.dateTime).format('h:mmA')}</Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Duration </Form.Label>  </Col>
                        <Col md={8}> <span className='colon'>:</span>
                            {product?.hours == 1 ? product?.hours + 'Hour ' : product?.hours > 1 ? product?.hours + 'Hours ' : ""}
                            {product?.minutes == 1 ? product?.minutes + 'Min ' : product?.minutes > 1 ? product?.minutes + 'Mins ' : ""}</Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Amount </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>${product?.price} </Col>
                    </Row>
                    <Row>
                        <Col md={4}><Form.Label> Payment Status </Form.Label>  </Col>
                        <Col md={8}><span className='colon'>:</span>{eventData?.payment_status == 'paid' ? "Paid" : "Not Paid"} </Col>
                    </Row>

                </Modal.Body>

            </Modal>
        </React.Fragment>
    );
}

export default EventDetails;