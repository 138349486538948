import { useEffect, useState } from "react";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { insertRecipe, auth, db, logout, storage, addCalanderAccess, getProductById } from "../../firebase";
import { ToastContainer, toast } from 'react-toastify';
import { query, collection, getDocs, where, increment, onSnapshot, doc, getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
import { getAuthRefreshToken, getEvents, insertEvent } from "../../utils/googleCalender";
import { Row, Col, Card, InputGroup, Form, Button, ProgressBar } from 'react-bootstrap'
import Calendar from 'react-calendar';
import moment from 'moment';
import { generateRandomString, timeSlotsArray } from "../../utils/dataUtils";
import CustomSpinner from "../CustomSpinner";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import EventDetails from "./EventDetails";

const BookedEvents = () => {

    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventData, setEventData] = useState(null);
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);


    useEffect(() => {
        console.log(user, "user")

        fetchUserName();
    }, [user, loading]);



    const fetchUserName = async () => {
        try {
            const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setUserData(data);

            const q2 = query(collection(db, process.env.REACT_APP_COLLECTION_STRIPE_PAYMENTS), where("user_id", "==", user?.uid))
            onSnapshot(q2, (querySnapshot) => {
                setEvents(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                })))
            })

        } catch (err) {
            console.error(err);
        }
    };

    const viewDetails = (event) => {
        setEventData(event)
        setModalShow(true);

    }

    const onHide = () => {
        setEventData(null);
        setModalShow(false);
    }

    console.log(events, "events")

    return (
        <div className={`${user ? 'page-container' : 'web-container'} eventPage`}>

            <EventDetails
                show={modalShow}
                onHide={onHide}
                eventData={eventData}
            />

            <div className='d-flex justify-content-between mb-2'>
                <h1 className='title-2'> My Events </h1>
                {/* <button className='theme-button' onClick={(e) => navigate("/addrecipe")}>Add Recipe <i class="fa fa-plus" ></i></button> */}
            </div>

            <Table bordered className="">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Event Name</th>
                        <th>Chef Email</th>
                        <th>Date </th>
                        <th>Time </th>
                        <th>Payment Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {events.length > 0 ? events?.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.event?.summary}</td>
                            <td>{item.event?.organizer.email}</td>
                            <td>{moment(item?.event?.start.dateTime).format('dddd, MMMM DD, YYYY')} </td>
                            <td>{moment(item?.event?.start.dateTime).format('h:mmA')} - {moment(item?.event?.end.dateTime).format('h:mmA')}</td>
                            <td>{item.payment_status}</td>
                            <td>
                                <i className="fa fa-eye" aria-hidden="true" onClick={() => viewDetails(item)}></i>
                            </td>

                        </tr>
                    )) : ""}


                </tbody>
            </Table>


        </div>

    )
}

export default BookedEvents;