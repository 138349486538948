import React from 'react'
import { RotatingLines } from "react-loader-spinner";

const Loader = ({ message }) => {
    return (
        <React.Fragment>
            <div className='spinner-container_wapper'>
                <div className="spinner-container">
                    <RotatingLines
                        strokeColor="red"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="60"
                        visible={true}
                    />
                    {/* <h4 className="mt-2">{message}</h4> */}
                </div>
            </div>

        </React.Fragment>

    )
}

export default Loader;