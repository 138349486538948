import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

// import Feed from './Feed'

// import './instaFeeds.css'

const InstaLikeComponent = ({token, ...props}) => {
    const [likefeeds, setLikeFeedsData] = useState([]);
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
          try{
            axios
                .get(`https://graph.facebook.com/17922633248070553/comments?access_token=${process.env.REACT_APP_INS_TOKEN}`)
                .then((resp) => {
                    console.log('resp',resp);
                    setLikeFeedsData(resp.data.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }

        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])
    console.log('Likefeeds',likefeeds);
    return (
        <div className="container_like">
            like
            {/* {feeds.map((feed) => (
               
                 <div>{feed.id}</div>
                // <Feed key={feed.id} feed={feed} />
            ))} */}
        </div>
    );
}

export default InstaLikeComponent;