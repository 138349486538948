import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { insertRecipe, auth, db, logout, storage, addCalanderAccess, insertProduct, updateProduct } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import { query, collection, getDocs, where, increment, onSnapshot, doc, getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
import { getAuthRefreshToken, getEvents, insertEvent } from "../utils/googleCalender";
import { Row, Col, Card, InputGroup, Form, Button, Modal, ProgressBar } from 'react-bootstrap'
import Calendar from 'react-calendar';
import moment from 'moment';
import { generateRandomString, timeSlotsArray } from "../utils/dataUtils";
import CustomSpinner from "./CustomSpinner";



const ProductForm = (props) => {
    const [user, loading, error] = useAuthState(auth);
    const [uploading, setUploading] = useState(false);
    const [inputField, setInputField] = useState({
        name: '',
        hours: '',
        minutes: '',
        price: '',
        currency: process.env.REACT_APP_CURRENCY,
        description: ''

    })
    const [errors, setErrors] = useState({})
    const priceRegEx = new RegExp('^\\d+(\\.\\d{1,2})?$');

    useEffect(() => {

        console.log(props, "props")

        if (props.action === 'edit') {
            setInputField(props.product)
        }

    }, [props])


    const inputsHandler = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        let sanitizedValue = value;

        let numberItems = ['hours', 'minutes'];


        if (numberItems.includes(name)) {
            sanitizedValue = value.replace(/[^\d]/g, '');

        }

        if (name === 'price') {

            sanitizedValue = value.replace(/[^\d.]/g, '');

            const dotIndex = sanitizedValue.indexOf('.');
            if (dotIndex !== -1 && sanitizedValue.indexOf('.', dotIndex + 1) !== -1) {
                sanitizedValue = sanitizedValue.substring(0, dotIndex + 1) + sanitizedValue.substring(dotIndex + 1).replace(/\./g, '');
            }

        }


        setInputField({ ...inputField, [name]: sanitizedValue });
        setErrors({ ...errors, [name]: '' });
    }



    const findErrors = () => {
        const { name, hours, minutes, description, price } = inputField;

        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Booking Description is required!'
        if (!description || description === '') newErrors.description = 'Booking description is required!'

        if (!price || price === '') newErrors.price = 'Price is required!'
        else if (!priceRegEx.test(price)) newErrors.price = 'Price is not valid!'

        if (!hours || hours === '') newErrors.hours = 'Hours is required!'
        else if (!hours > 24) newErrors.hours = 'Hours is not valid!'

        if (!minutes || minutes === '') newErrors.minutes = 'Minutes is required!'
        else if (!minutes > 24) newErrors.minutes = 'Minutes is not valid!'


        return newErrors
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);

        const newErrors = findErrors();
        console.log(newErrors, "newErrors");

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setUploading(false);

        } else {

            let payload = { ...inputField, price: parseFloat(inputField.price), stripeAccountId: props.userData.stripeAccountId }
            console.log(payload, "payload")

            axios.post(`${process.env.REACT_APP_API_URL}/stripe/createProduct`, payload)
                .then(res => {
                    console.log(res)

                    if (res.status === 200) {
                        setUploading(false);
                        toast.success('Product created sucessfully!')
                        onClose();
                    } else {
                        setUploading(false);
                        toast.error('Product is not created!')
                    }

                })
                .catch(err => {
                    console.log(err, "err");
                    setUploading(false);
                    toast.error('Product is not created!')
                });
        }
    }

    const onUpdate = async (e) => {
        e.preventDefault();
        setUploading(true);

        const newErrors = findErrors();
        console.log(newErrors, "newErrors");

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setUploading(false);

        } else {

            console.log(inputField, "payload")

            let payload = {
                id: inputField.id,
                name: inputField.name,
                description: inputField.description,
                currency: inputField.currency,
                price_id: inputField.price_id,
                product_id: inputField.product_id,
                price: parseFloat(inputField.price),
                stripeAccountId: props.userData.stripeAccountId
            }
            console.log(payload, "payload")

            axios.put(`${process.env.REACT_APP_API_URL}/stripe/updateProduct`, payload)
                .then(res => {
                    console.log(res)

                    if (res.status === 200) {
                        setUploading(false);
                        toast.success('Booking product updated sucessfully!')
                        onClose();
                    } else {
                        setUploading(false);
                        toast.error('Booking product is not updated!')
                    }

                })
                .catch(err => {
                    console.log(err, "err");
                    setUploading(false);
                    toast.error('Booking product is not updated!')
                });


        }
    }

    const onClose = () => {
        console.log("close")
        setInputField({
            name: '',
            hours: '',
            minutes: '',
            price: '',
            currency: process.env.REACT_APP_CURRENCY,
            description: ''

        })
        props.onHide();


    }

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={onClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.action === 'add' ? 'Add' : 'Edit'} Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>




                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                        <Form.Label> Booking Product Name*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="name"
                            value={inputField.name}
                            placeholder="Product Name "
                            onChange={inputsHandler}
                            isInvalid={!!errors.name}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                        <Form.Label>Booking Product Description *</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            name="description"
                            value={inputField.description}
                            placeholder="Description "
                            onChange={inputsHandler}
                            isInvalid={!!errors.description}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Row>

                        <Form.Label as={Col} md="8">Booking Product Duration *</Form.Label>

                        <Form.Label as={Col} md="4">Booking Product Price *</Form.Label>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <InputGroup className="mb-3">

                                <Form.Control
                                    required
                                    type="text"
                                    name="hours"
                                    value={inputField.hours}
                                    placeholder="Hours "
                                    onChange={inputsHandler}
                                    isInvalid={!!errors.hours}
                                />
                                <InputGroup.Text>
                                    hr
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {errors.hours}
                                </Form.Control.Feedback>
                            </InputGroup>


                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <InputGroup className="mb-3">

                                <Form.Control
                                    required
                                    type="text"
                                    name="minutes"
                                    value={inputField.minutes}
                                    placeholder="Minutes "
                                    onChange={inputsHandler}
                                    isInvalid={!!errors.minutes}
                                />
                                <InputGroup.Text>
                                    min
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {errors.minutes}
                                </Form.Control.Feedback>

                            </InputGroup>

                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required
                                    type="text"
                                    name="price"
                                    value={inputField.price}
                                    placeholder="Price "
                                    onChange={inputsHandler}
                                    isInvalid={!!errors.price}
                                />
                                <InputGroup.Text>
                                    $
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {errors.price}
                                </Form.Control.Feedback>

                            </InputGroup>

                        </Form.Group>


                    </Row>



                </Modal.Body>
                <Modal.Footer>
                    <button className="theme-button-secondary " style={{ marginRight: '15px' }} onClick={onClose}>Back</button>

                    {props.action === 'add' ?
                        <button className='theme-button' onClick={onSubmit} disabled={uploading}> Create Booking Product
                            <CustomSpinner status={uploading} />
                        </button>
                        :
                        <button className='theme-button' onClick={onUpdate} disabled={uploading}> Update Booking Product
                            <CustomSpinner status={uploading} />
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ProductForm;
