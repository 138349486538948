import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
// import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Header1 from './header1.js'
import { Link, useNavigate } from "react-router-dom";
import ProfileImage from './images/profile.png'
import ProfileSearch from './SearchProfiles.js'
// import Serving_Size_Icon from './images/serving_size.png'
// import Min_Cook_Icon from './images/min_cook.png'
// import ReciLogo from './images/ReciLogo.png'
// import Gem_Badge from './images/gem_badge.png'
// import Shelf_Icon from './images/Shelf.png'
// import User_Icon from './images/user.png'
// import Website_Icon from './images/website.png'
// import Cook_Icon from './images/cook.png'
// import Calendar_Icon from './images/calendar.png'
// import Share_Icon from './images/share.png'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   makeStyles,
//   Rating
// } from "@material-ui/core";
import Rating from '@mui/material/Rating';
import { useDispatch } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
// import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import { changeprofile } from './_actions/profileActions'
import { useAuthState } from "react-firebase-hooks/auth";
import { insertRecipe, auth, db, logout, storage } from "./firebase";
import { query, collection, getDocs, where, increment, onSnapshot } from "firebase/firestore";
import { Button } from 'react-bootstrap';

function Profiles() {

  // const [info , setInfo] = useState([]);
  // const [Recis, setRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [logged_user_id, setLoggedUserId] = useState("");
  const searchClient = algoliasearch('SXH1UNR77P', '5cbea671c4708dfc63c7ff6845a218a2');
  const navigate = useNavigate();

  // const opts = {
  //     height: '600',
  //     width: '90%',
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //     },
  //   };
  // const divStyle = {
  //   paddingTop: '100px'
  // };

  // const ColoredLine = ({ color }) => (
  //   <hr
  //     style={{
  //       color,
  //       backgroundColor: color,
  //       height: .5
  //     }}
  //   />
  // );

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_USERS).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);
  // useEffect(() => {
  //   const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
  //   onSnapshot(q, (querySnapshot) => {
  //     setRecis(querySnapshot.docs.map(doc => ({
  //       id: doc.id,
  //       data: doc.data(),
  //     })))
  //   })
  //  }, []);
  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
    onSnapshot(q, (querySnapshot) => {
      var data = querySnapshot?.docs?.map(doc => ({
        id: doc?.id,
        data: doc?.data(),
      }))
      setUsers(data ? data : [])
    })
    fetchUserName();
  }, [user, loading]);

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setLoggedUserId(data);
    } catch (err) {
      console.error(err);
    }
  };


  //    const fetchUserName = async () => {
  //     try {
  //        const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
  //        const doc = await getDocs(q);
  //        const data = doc.docs[0].data();
  //        console.log(data, "user data")
  //        setName(data.name);
  //        setInputField({
  //           ...inputField, 'profile_pic': data.profile_pic,
  //           'myID': data.myID,
  //           'profile_URL': '#',
  //           'author': data.name,
  //           'reci_URL': '#',
  //        })
  //     } catch (err) {
  //        console.error(err);
  //     }
  //  };

  const onBooking = (id) => {

    navigate(`/bookProduct?profile=${id}`)

  }


  return (
    <div className='web-container'>

      <ProfileSearch />

      {Users?.length > 0 ?

        <Grid container spacing={5}>
          {Users?.length > 0 && Users?.map(({ id, data }) => {
            if (data?.uid !== user?.uid) {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={id} >

                  <Paper className='profile_box' >

                    <Link style={{ textDecoration: 'none', color: '#000' }} onClick={() => dispatch(changeprofile(id))} to={`/ProfileSelected/${id}`}>

                      <div className='profile_picture'>
                        {data?.profile_pic ? (
                          <img src={data.profile_pic} alt="User" width="100" height="100" />
                        ) : (
                          <img src={ProfileImage} alt="User" width="100" height="100" />
                        )}
                      </div>
                      <div className='profile_name'>
                        {data?.name ? (
                          <h4>{data?.name}</h4>
                        ) : ("")}
                      </div>
                    </Link>

                    {/* <Button> Book Me </Button> */}
                    {(data?.userType === 'Chef' ) &&
                      <button className="book__btn" onClick={() => (user) ? onBooking(data.uid) : navigate('/login')}>
                        Book me
                      </button>
                    }

                  </Paper>

                </Grid>
              )
            }
          }

          )}
        </Grid>

        : <div className="text-center" >No records found</div>}

    </div>

  );
}

export default Profiles;
