import { useState } from "react"
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';



const Ingredients = ({ ingredients, setIngredients, errors, setErrors }) => {
    // const [ingredients, setIngredients] = useState(data)

    const onChange = (e, id) => {
        const { name, value } = e.target;

        const editData = ingredients.map((item) => {
            if (item.id === id && name) {
                let sanitizedValue = value;

                // let numberItems = ['Amount']
                // if (numberItems.includes(name)) {
                //     sanitizedValue = value.replace(/[^0-9.]/g, '');

                //     const dotIndex = sanitizedValue.indexOf('.');
                //     if (dotIndex !== -1 && sanitizedValue.indexOf('.', dotIndex + 1) !== -1) {
                //         sanitizedValue = sanitizedValue.substring(0, dotIndex + 1) + sanitizedValue.substring(dotIndex + 1).replace(/\./g, '');
                //     }
                // }

                return { ...item, [name]: sanitizedValue };
            }

            return item;
        });

        setErrors({
            ...errors,
            ingredients: {
                ...errors.ingredients,
                [id]: {
                    ...errors.ingredients?.[id],
                    [name]: ''
                }
            },
        });
        setIngredients(editData);
    };

    const removeIngredient = (id) => {
        const index = ingredients.findIndex(item => item.id === id);
        console.log(index, "index")
        if (index > -1) {
            const updatedingredients = [...ingredients];
            updatedingredients.splice(index, 1);
            setIngredients(updatedingredients);
        }

    }

    const addIngredient = () => {
        const newingredients = [...ingredients, {
            id: ingredients.length + 1,
            Ingredient: '',
            Amount: '',
            Units: '',
        }];
        setIngredients(newingredients);


    }

    return (
        <div className="ingredients_field mb-2">
            <div className="recipe_main-heading">
                <h4>Ingredients</h4>

                <i className="fa fa-plus-square" aria-hidden="true" onClick={addIngredient} style={{ color: '#fff', fontSize: "30px" }}></i>

            </div>
            <table>
                <thead>
                    <tr>
                        <th>Ingredient</th>
                        <th>Amount</th>
                        <th>Units</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {ingredients.map(({ id, Ingredient, Amount, Units }) => (
                        <tr key={id}>
                            <td>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="Ingredient"
                                        value={Ingredient}
                                        placeholder="Ingredient *"
                                        onChange={(e) => onChange(e, id)}
                                        isInvalid={!!(errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Ingredient'])}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Ingredient']}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </td>
                            <td>

                                <Form.Group controlId="validationCustom02">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="Amount"
                                        value={Amount}
                                        placeholder="Amount *"
                                        onChange={(e) => onChange(e, id)}
                                        isInvalid={!!(errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Amount'])}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Amount']}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </td>
                            <td>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="Units"
                                        value={Units}
                                        placeholder="Kg"
                                        onChange={(e) => onChange(e, id)}
                                        isInvalid={!!(errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Units'])}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ingredients && errors.ingredients[id] && errors.ingredients[id]['Units']}
                                    </Form.Control.Feedback>
                                </Form.Group>


                            </td>
                            <td className="text-center">
                                {
                                    id !== 1 &&
                                    <i className="fa fa-trash-o" aria-hidden="true" onClick={e => removeIngredient(id)} style={{ color: 'red', fontSize: "26px" }}></i>

                                }
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Ingredients;