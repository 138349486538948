import { cookbook } from '../_constants';
const initialState = {
cookbookinput: 'No cookbook selected',
cookbookinput1: 'No cookbook selected'
};
export default function cookbookreducer(state = initialState, action)
{
switch(action.type) {
case "SELECTED_COOKBOOK":
return { ...state, cookbookinput: action.payload };
      console.log("cookbook", state.cookbook);
default:
return state;
}
switch(action.type) {
case "SELECTED_COOKBOOK1":
return { ...state, cookbookinput1: action.payload };
      console.log("cookbook", state.cookbook);
default:
return state;
}
}
