import React from 'react';
import { insertRecipe, auth, db, logout, storage, addCalanderAccess } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Form, Col } from 'react-bootstrap'

const UserProfile = () => {
    const [user, loading, error] = useAuthState(auth);

    return (
        <div className={`${user ? 'page-container' : 'web-container'}`}>
            <div className='d-flex justify-content-between mb-2'>
                <h1 className='title-2'> My Profile </h1>
            </div>

            <div style={{ width: '70%', margin: 'auto' }}>
                {/* <Form.Group className="mb-2" controlId="validationCustom02">
                    <Form.Label>Name: </Form.Label>
                    {user?.displayName}
                </Form.Group>
                <Form.Group className="mb-2" controlId="validationCustom02">
                    <Form.Label>Email: </Form.Label>
                    {user?.email} 
                </Form.Group> */}

            </div>

        </div>
    )
}

export default UserProfile;