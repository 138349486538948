import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Googleicon from './images/googleicon.png'
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  updateDoc,
  where,
  addDoc,
  doc

} from "firebase/firestore";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}');

  const [inputField, setInputField] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(async() => {
    if (loading) {
      return;
    }

    console.log(user, "user_id",)
    if (user) {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        navigate("/dashboard");
      }

    }
  }, [user, loading]);

  const inputsHandler = (e) => {
    e.preventDefault();
    setInputField({ ...inputField, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const findErrors = () => {
    const {  email, password, } = inputField;

    const newErrors = {}


    if (!email || email === '') newErrors.email = 'Email is required!'
    else if (!emailRegex.test(email)) newErrors.email = 'Email not valid!'
    if (!password || password === '') newErrors.password = 'Password is required!'

    return newErrors
  }


  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = findErrors();
    console.log(newErrors, "newErrors");

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      logInWithEmailAndPassword(inputField.email, inputField.password);

    }
  }

  return (
    <div className="login">
      <div className="login__container">
        <div className="form_head">
          <h2>Log In</h2>
          <p>Don't have an account? <Link to="/signup">Signup</Link> now.</p> 
        </div>

        <Form noValidate onSubmit={handleSubmit}>
          <Row>

            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Control
                className="theme-input"
                required
                type="text"
                name="email"
                value={inputField.email}
                placeholder="E-mail Address *"
                onChange={inputsHandler}
                isInvalid={!!errors.email}

              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Control
                required
                className="theme-input"
                type="password"
                name="password"
                value={inputField.password}
                placeholder="Password *"
                onChange={inputsHandler}
                isInvalid={!!errors.password}

              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>


            <Col className="mb-4">
            <button className="login__btn"  >
              Login
            </button>
          </Col>

          </Row>
        </Form>

      

        <br/>
        <div className="striped">
          <span className="striped-line"></span>
          <span className="striped-text">Or</span>
          <span className="striped-line"></span>
        </div>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          <img src={Googleicon} />
          Login with Google
        </button>
        <div>
          {/* <Link to="/reset">Forgot Password</Link> */}
        </div>
        
      </div>
    </div>
  );
}

export default Login;