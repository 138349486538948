import { profile } from '../_constants/index.js';
const initialState = {
profileinput: 'Reci'
};
export default function profilereducer(state = initialState, action)
{
switch(action.type) {
case "profile":
return { ...state, profileinput: action.payload };
      console.log("profile", state.post);
default:
return state;
}
}
