import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  updateDoc,
  where,
  addDoc,
  doc,
  deleteDoc,
  getDoc
} from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';
import { getGoogleCalendarAccess } from "./utils/googleCalender";

// DEvelopment
const firebaseConfig = {
  apiKey: "AIzaSyApzPKbS9qMlCUxnPtzVtQAziJdsX5VFIg",
  authDomain: "reci-bec70.firebaseapp.com",
  databaseURL: "https://reci-bec70-default-rtdb.firebaseio.com",
  projectId: "reci-bec70",
  storageBucket: "reci-bec70.appspot.com",
  messagingSenderId: "52665841788",
  appId: "1:52665841788:ios:a98e881363603a70c671f9",
  measurementId: "G-XDRFPV6Z2Z"
};

//LIVE
// const firebaseConfig = {
//   apiKey: "AIzaSyBkxbD3RjpBglmVjhqoXSPpHUkT-hfGi2E",
//   authDomain: "reci-bec70.firebaseapp.com",
//   databaseURL: "https://reci-bec70-default-rtdb.firebaseio.com/",
//   projectId: "reci-bec70",
//   storageBucket: "reci-bec70.appspot.com",
//   messagingSenderId: "52665841788",
//   appId: "1:52665841788:ios:a98e881363603a70c671f9"
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user, "user")

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      signOut(auth);
      toast.error("You don't have an account. Signup before login");
    } else {
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData?.userType === 'Chef' && !userData?.calendarAccess) {
          getGoogleCalendarAccess(userData.uid);
        } else {
          toast.success("Login Successfully");
        }
      });
    }

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signUpWithGoogle = async (inputField) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // User document doesn't exist, create a new user document
      const response = await addDoc(collection(db, process.env.REACT_APP_COLLECTION_USERS), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        profile_pic: user.photoURL,
        followers: ["Reci", "Pratik"],
        email: user.email,
        userType: inputField.userType,
        stripeAccount: false,
        calendarAccess: false,
        calendarAccessData: null,
      });

      if (inputField.userType === 'Chef') {
        getGoogleCalendarAccess(user.uid);

      }

    } else {
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        console.log(userData, "userData");
        if (userData.userType === 'Chef' && !userData.calendarAccess) {
          getGoogleCalendarAccess(userData?.uid);

        }
      });
    }


  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {

  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(user, "user")

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      console.log(userData, "userData", doc.id);
      if (userData.userType === 'Chef' && !userData.calendarAccess) {
        getGoogleCalendarAccess(userData?.uid);
      } else {
        toast.success("Login Successfully");
      }
    });

  } catch (error) {
    if (error.code) {
      switch (error.code) {
        case 'auth/invalid-email':
          toast.error("Invalid email account.");
          break;
        case 'auth/wrong-password':
          toast.error("Wrong password.");
          break;
        case 'auth/user-not-found':
          toast.error("User not found.");
          break;
        default:
          toast.error("An error occurred during authentication.");
      }
    } else {
      toast.error("An unexpected error occurred.");
    }

    return error;

  }
};

const registerWithEmailAndPassword = async (data) => {
  const { name, email, password, userType } = data;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const response = await addDoc(collection(db, process.env.REACT_APP_COLLECTION_USERS), {});
    const docRef = doc(db, process.env.REACT_APP_COLLECTION_USERS, response.id);
    const data = {
      uid: user.uid,
      name: name,
      authProvider: "local",
      myID: response.id,
      user_id: response.id,
      profile_pic: user.photoURL,
      followers: ["Reci", "Pratik"],
      email: user.email,
      userType: userType,
      stripeAccount: false,
      calendarAccess: false,
      calendarAccessData: null,
    };
    updateDoc(docRef, data)
      .then(docRef => {
        if (userType === 'Chef') {
          getGoogleCalendarAccess(user?.uid);
        }
      })
      .catch(error => {
        console.log(error.code, "err")
      })
  } catch (error) {
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          toast.error("Email is already in use.");
          break;
        case 'auth/invalid-email':
          toast.error("Invalid email address.");
          break;
        default:
          toast.error("An error occurred during authentication.");
      }
    } else {
      toast.error("An unexpected error occurred.");
    }
    return error;
  }
};

const addCalanderAccess = async (inputField) => {
  console.log(inputField, "data")
  try {

    const userQuery = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", inputField?.id));

    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.size > 0) {
      const userDoc = querySnapshot.docs[0];

      const data = {
        calendarAccess: true,
        calendarAccessData: inputField?.accessData,
      };

      await updateDoc(userDoc.ref, data);

      console.log("Document updated successfully");
    } else {
      console.error("Document not found for the given uid");
    }

    // const docRef = doc(db, process.env.REACT_APP_COLLECTION_USERS, inputField.id);
    // const data = {
    //   calendarAccess: true,
    //   calendarAccessData: inputField.accessData,
    // };

    // await updateDoc(docRef, data);

    return {
      message: "Got calender access successfully"
    };

  } catch (err) {
    return {
      message: err.message
    };
  }
};


const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};



const insertRecipe = async (inputField) => {
  try {

    // const res = await createUserWithEmailAndPassword(auth, email, password);
    // const user = res.user;
    const response = await addDoc(collection(db, process.env.REACT_APP_COLLECTION_POSTS), {


    });
    console.log("xxxx", response.id);



    const docRef = doc(db, process.env.REACT_APP_COLLECTION_POSTS, response.id);

    const data = {
      Cook_Count: 0,
      Nutrition: {
        'Carbs': { 'Amount': inputField.carbs_amount, 'Units': inputField.carbs_units },
        'Energy': { 'Amount': inputField.energy_amount, 'Units': inputField.energy_units },
        'Fat': { 'Amount': inputField.fat_amount, 'Units': inputField.fat_units },
        'Protein': { 'Amount': inputField.protein_amount, 'Units': inputField.protein_units },
        'Sugars': { 'Amount': inputField.sugars_amount, 'Units': inputField.sugar_units },
      },

      Reci: {
        'Ingredients': inputField.Ingredients,
        'Steps': inputField.Steps
      },
      Cuisine: inputField.recipe_cuisine,
      Title: inputField.recipe_title,
      Type: inputField.Video,
      Upload_Date: Date.now(),
      Website_URL: inputField.website_url,
      YouTube_URL: inputField.youtube_url || inputField.Videofile,
      author: [{ 'Name': inputField.author, 'profile_URL': inputField.profile_URL, 'reci_URL': inputField.reci_URL }],
      min_cook: inputField.cook_time,
      myID: response.id,
      post_id: '',
      profile_pic: inputField.profile_pic,
      serving_size: inputField.serving_size,
      user_id: inputField.user_id,
      own_recipe: inputField.own_recipe,
      userdoc_id: inputField.userdoc_id
    };

    console.log(docRef);
    console.log('docRefdocRef', data);

    await updateDoc(docRef, data);

    console.log(response.id, "response.id")
    return {
      id: response.id,
      message: "Recipe added successfully"
    };

  } catch (err) {
    return {
      message: err.message
    };
  }
};

const updateRecipe = async (inputField) => {
  try {

    const docRef = doc(db, process.env.REACT_APP_COLLECTION_POSTS, inputField.id);

    const data = {
      Cook_Count: 0,
      Nutrition: {
        'Carbs': { 'Amount': inputField.carbs_amount, 'Units': inputField.carbs_units },
        'Energy': { 'Amount': inputField.energy_amount, 'Units': inputField.energy_units },
        'Fat': { 'Amount': inputField.fat_amount, 'Units': inputField.fat_units },
        'Protein': { 'Amount': inputField.protein_amount, 'Units': inputField.protein_units },
        'Sugars': { 'Amount': inputField.sugars_amount, 'Units': inputField.sugar_units },
      },

      Reci: {
        'Ingredients': inputField.Ingredients,
        'Steps': inputField.Steps
      },
      Cuisine: inputField.recipe_cuisine,
      Title: inputField.recipe_title,
      Type: inputField.Video,
      Upload_Date: Date.now(),
      Website_URL: inputField.website_url,
      YouTube_URL: inputField.youtube_url || inputField.Videofile,
      author: [{ 'Name': inputField.author, 'profile_URL': inputField.profile_URL, 'reci_URL': inputField.reci_URL }],
      min_cook: inputField.cook_time,
      myID: inputField.id,
      post_id: '',
      profile_pic: inputField.profile_pic,
      serving_size: inputField.serving_size,
      user_id: '',
      own_recipe: inputField.own_recipe,
      userdoc_id: inputField.userdoc_id
    };

    console.log(docRef);
    console.log('docRefdocRef', data);

    await updateDoc(docRef, data);

    return {
      message: "Recipe updated successfully"
    };


  } catch (err) {
    return {
      message: err.message
    };
  }
};

const reinsertRecipe = async (inputField) => {
  try {

    // const res = await createUserWithEmailAndPassword(auth, email, password);
    // const user = res.user;

    console.log('fdg55', inputField)
    const response = await addDoc(collection(db, process.env.REACT_APP_COLLECTION_POSTS), {


    });
    console.log("xxxx", response.id);
    inputField.myID = response.id;
    inputField.own_recipe = '2';

    const docRef = doc(db, process.env.REACT_APP_COLLECTION_POSTS, response.id);
    console.log('docRefdocRef', inputField);

    updateDoc(docRef, inputField)
      .then(docRef => {
        window.location = `/SelectedReci/${response.id}`;
        console.log("A New Document Field has been added to an existing document");
      })
      .catch(error => {
        console.log(error);
      })

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const insertEventData = async (payment_id, eventData) => {

  const paymentData = {
    event: eventData,
    eventBooking_status: true
  }

  const paymentRef = doc(db, process.env.REACT_APP_COLLECTION_STRIPE_PAYMENTS, payment_id);

  await updateDoc(paymentRef, paymentData);

  return updateDoc(paymentRef, paymentData)
    .then(async (docRef) => {
      return 1;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      return 0;
    });
};

const insertProduct = async (data) => {

  return await addDoc(collection(db, process.env.REACT_APP_COLLECTION_STRIPE_PRODUCTS), data)
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
      return 1;
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      return 0;
    });
};

const updateProduct = async (data) => {

  try {
    const docRef = doc(db, process.env.REACT_APP_COLLECTION_STRIPE_PRODUCTS, data.id);
    await updateDoc(docRef, data);
    console.log("Document updated with ID: ", data.id);
    return 1;
  } catch (error) {
    console.error("Error updating document: ", error);
    return 0;
  }
};

const deleteProduct = async (id) => {
  console.log(id, "id")
  try {
    const docRef = doc(db, process.env.REACT_APP_COLLECTION_STRIPE_PRODUCTS, id);
    await deleteDoc(docRef);
    console.log("Document deleted with ID: ", id);
    return 1;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return 0;
  }
};

const getProducts = async (stripeAccountId) => {
  try {
    console.log(stripeAccountId, "stripeAccountId")
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_STRIPE_PRODUCTS), where("stripeAccountId", "==", stripeAccountId));
    const querySnapshot = await getDocs(q);
    const products = [];
    querySnapshot.forEach((doc) => {
      products.push({ id: doc.id, ...doc.data() });
    });
    console.log(products, "products")
    return products;
  } catch (error) {
    console.error("Error getting products:", error);
    return 0;
  }
};

const getProductById = async (id) => {
  try {
    const docRef = doc(collection(db, process.env.REACT_APP_COLLECTION_STRIPE_PRODUCTS), id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting product:", error);
    return null;
  }
};

const createAIRecipe = async (url) => {
  try {
    const response = await addDoc(collection(db, process.env.REACT_APP_COLLECTION_USERS), {});
    const docRef = doc(db, process.env.REACT_APP_COLLECTION_POSTS, response.id);
    let data = {
      url: url,
      user_id: response?.id
    }
    await updateDoc(docRef, data);
  } catch (error) {
    console.error("Error getting product:", error);
    return null;
  }
};



export {
  auth,
  db,
  storage,
  insertRecipe,
  reinsertRecipe,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  updateRecipe,
  addCalanderAccess,
  signUpWithGoogle,
  insertEventData,
  insertProduct,
  updateProduct,
  deleteProduct,
  getProducts,
  getProductById,
  createAIRecipe
};
