import { useState } from "react"
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';

const Instructions = ({ steps, setSteps, errors, setErrors }) => {

    const onChange = (e, id) => {
        const { name, value } = e.target

        const editData = steps.map((item) =>
            item.id === id && name ? { ...item, [name]: value } : item
        )

        setErrors({
            ...errors,
            steps: {
                ...errors.steps,
                [id]: {
                    ...errors.ingredients?.[id],
                    [name]: ''
                }
            },
        });

        setSteps(editData)
    }

    const removeItem = (id) => {
        const index = steps.findIndex(item => item.id === id);
        console.log(index, "index")
        if (index > -1) {
            const updatedsteps = [...steps];
            updatedsteps.splice(index, 1);
            setSteps(updatedsteps);
        }

    }

    const addItem = () => {
        const newsteps = [...steps, {
            id: steps.length + 1,
            step: ''
        }];
        setSteps(newsteps);


    }

    return (
        <div className="ingredients_field mb-2">
            <div className="recipe_main-heading">
                <h4>Steps</h4>

                <i className="fa fa-plus-square" aria-hidden="true" onClick={addItem} style={{ color: '#fff', fontSize: "30px" }}></i>

            </div>
            <table>
                <thead>
                    <tr>
                        <th>Steps</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {steps.map(({ id, step }) => (
                        <tr key={id}>
                            <td>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="step"
                                        value={step}
                                        placeholder="Steps *"
                                        onChange={(e) => onChange(e, id)}
                                        isInvalid={!!(errors.steps && errors.steps[id] && errors.steps[id]['step'])}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.steps && errors.steps[id] && errors.steps[id]['step']}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </td>
                          
                            <td className="text-center">
                                {
                                    id !== 1 &&
                                    <i className="fa fa-trash-o" aria-hidden="true" onClick={e => removeItem(id)} style={{ color: 'red', fontSize: "26px" }}></i>

                                }
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Instructions;