import React, { useEffect } from "react";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { getAuthRefreshToken } from "./googleCalender";

import { insertRecipe, auth, db, logout, storage, addCalanderAccess, deleteProduct, getProducts } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const CalendarCallback = () => {
    const [user, loading, error] = useAuthState(auth);

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const idParam = queryParams.get('id');
    const user_id = localStorage.getItem("userAuthId");
    const codeParam = queryParams.get('code');
    const scopeParam = queryParams.get('scope');
    console.log('params', idParam, codeParam, scopeParam);



    useEffect(() => {
        let data = getAuthRefreshToken(user_id, codeParam);
        data
            .then((response) => {
                if (response) {
                    navigate('/dashboard');
                    window.location.reload();
                }
            }).catch((err) => {
                console.log("err", err);
            })
    }, [codeParam, user_id]);


    return (
        <div>

        </div>
    );
}

export default CalendarCallback;