import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import { reinsertRecipe, auth, db } from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import { useAuthState } from "react-firebase-hooks/auth";

import orignal_recipie from './images/original.png'
import copy_recipie from './images/repost.png'
import retweetImg from './images/retweet.png'

import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import Health_Icon from './images/Health.png'
import LiveStream_Icon from './images/LiveStream.png'
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import store from './_store/configurestore.js';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changepost } from './_actions/postActions'
import Nutrition_Icon from './images/nutrition.png'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from 'react-player'
import { query, collection, getDocs, onSnapshot, where } from "firebase/firestore";

import { changeprofile } from './_actions/profileActions'
import ProfileImage from './images/profile.png'


function ProfileSelected(props) {

  const [user] = useAuthState(auth);
  const [logged_user_id, setLoggedUserId] = useState("");
  const navigate = useNavigate();


  const [info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  const [RepostId, SetRespotId] = useState([]);
  const [getRepost, setRepostRecis] = useState([]);

  const state = useSelector((state) => state);
  const params = useParams();
  console.log('params.username', params.username);
  const opts = {
    height: '600',
    width: '90%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const dispatch = useDispatch();
  const [inputField, setInputField] = useState([])
  const divStyle = {
    paddingTop: '100px'
  };
  const modalstyle = {
    display: 'none'
  };



  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: .5
      }}
    />
  );

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_USERS).where("myID", "==", params.username).onSnapshot((snapshot) => {
  //     setUsers(
  //       snapshot.docs.map((doc) => ({
  //         id2: doc.id,
  //         data2: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Users });
  // }, []);



  useEffect(() => {
    // const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("myID", "==", params?.username))
    // onSnapshot(q, (querySnapshot) => {
    //   setUsers(querySnapshot?.docs?.map(doc => ({
    //     id2: doc?.id,
    //     data2: doc?.data(),
    //   })))
    // })

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
    onSnapshot(q, (querySnapshot) => {
      var data = querySnapshot?.docs?.map(doc => ({
        id: doc?.id,
        data: doc?.data(),
      }))
      var getOneUser = data.filter((item) => item?.id === params?.username)
      setUsers(getOneUser)
    })


  }, []);

  useEffect(() => {
    fetchUserName();
  }, []);

  useEffect(() => {
    // const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("userdoc_id", "==", params.username))
    // onSnapshot(q, (querySnapshot) => {
    //   setRecis(querySnapshot.docs.map(doc => ({
    //     id: doc.id,
    //     data: doc.data(),
    //   })))
    // })

    const q = query(collection(db, 'posts'))
    onSnapshot(q, (querySnapshot) => {
      var FetchItem = querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      }))
      FetchItem.map((data) => {
        if (data?.data?.userdoc_id == params?.username || data?.data?.user_id == params?.username) {
          setRecis((prevRecis) => [...prevRecis, data]);
        }
      })
    })
  }, []);
  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_POSTS).where("userdoc_id", "==", params.username).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setInfo(doc.docs[0]?.id)
      console.log('loggedin user', data);
      setLoggedUserId(data);
    } catch (err) {
      console.error(err);
    }
  };

  const popuphandler = (postid) => {
    console.log(postid);
    SetRespotId(postid)
    document.getElementById('myModal').style = 'display:block';
  };

  const popupClosehandler = () => {
    document.getElementById('myModal').style = 'display:none';
  };

  const reposthandler = async () => {
    console.log('RepostId', RepostId)
    document.getElementById('myModal').style = 'display:none';

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", RepostId));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();
    setTimeout(function () {
      data.userdoc_id = info;
      data.user_id = doc.docs[0].id;
      data.profile_pic = logged_user_id.profile_pic;
      data.repost_id = RepostId;
      reinsertRecipe(data)
    }, 1500);
    // const qs = await query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", RepostId))
    // onSnapshot(qs, (querySnapshot) => {
    //   setRepostRecis(querySnapshot.docs.map(doc => ({
    //     data: doc.data(),
    //   })))
    // })




  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://connect.bingewave.com/connect.js";
    script.async = true;

    document.body.appendChild(script);

    setTimeout(() => { window.BingewaveConnector?.init(); }, 1000);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const onBooking = (id) => {
    navigate(`/bookProduct?profile=${id}`)
  }

  return (
    <div className='web-container'>
      <Container className='text-center'>
        <Col>
          {Users?.map(({ id, data }) => (<img src={data?.profile_pic ? data?.profile_pic : ProfileImage} alt="User" style={{ borderRadius: '50%', objectFit: "cover", objectPosition: "center" }} width="100" height="100" />))}
        </Col>
        <Col className='mt-3 mb-3'>
          {Users?.map(({ id, data }) => {
            return (
              <>
                <h4>{data.name}</h4>
                <p>@{data.user_id}</p>
                <p><b style={{color: "red"}}>Recis:</b> {Recis.length}</p>
                {(data?.userType === 'Chef' && data?.stripeAccount && data?.uid !== logged_user_id?.uid) &&
                  <button className="book__btn" onClick={() => (user) ? onBooking(data?.uid) : navigate('/login')}>
                    Book me
                  </button>
                }
              </>
            )
          })}

        </Col>
      </Container>

      <Grid container spacing={2}>
        {Recis?.reverse()?.map(({ id, data }) => (
          <Grid item xs={12} sm={12} md={4} key={id} className="recis_video_box">
            <Paper>
              <div className='recis_video_box_detail'>

                <p><b>{data.Cuisine}</b>: {data.Title} </p>
                <p><b>Author(s) ({data.author.length})</b>
                {/*  <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data?.user_id ? data?.user_id : data.userdoc_id}`}>{data.author ? data.author[0]?.Name : ""} </Link>*/}
                   {data.author.map((name, index) => <a key={name} href={data.author[index].profile_URL}>{data.author[index].Name}</a>)}
                </p>

                <Row className='reci-details mb-4 gy-3'>
                  <Col xl={4}>
                    <img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> <span>Serving Size: {data.serving_size}</span>
                  </Col>
                  <Col xl={4}>
                    <img src={Min_Cook_Icon} style={{ marginRight: '5px' }} alt="Min Cook" width="20" height="20" /><span>Min Cook: {data.min_cook}min</span>
                  </Col>

                  {data.own_recipe == 1 ? (<Col xl={4}><img src={orignal_recipie} alt="orignal image" width="20" height="20" /> <span>Orignal</span></Col>) : (<Col xl={4}><img src={copy_recipie} alt="orignal image" width="20" height="20" /> <span>Repost</span></Col>)}
                </Row>

                {/* <div className='reci_headerss'>
                  <p><img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> <span>Serving Size: {data.serving_size}</span> </p>
                  <p><img src={Min_Cook_Icon} alt="Min Cook" width="20" height="20" /><span>Min Cook: {data.min_cook}min</span></p>
                  {data.own_recipe == 1 ? (<p><img src={orignal_recipie} alt="orignal image" width="20" height="20" /> <span>Orignal</span></p>) : (<p><img src={copy_recipie} alt="orignal image" width="20" height="20" /> <span>Repost</span></p>)}
                </div> */}

                <ReactPlayer url={data.YouTube_URL} style={{ backgroundColor: '#000000' }} width="100%" controls="true" />

                <div className="mx-0 profile_list reci-tools">
                  <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}><Button as={Col} variant="primary"><img src={data.profile_pic ? data.profile_pic : ProfileImage} alt="User" width="30" height="30" /></Button></Link>
                  <Link to={`/SelectedReci/${id}`}><Button onClick={() => dispatch(changepost(data.post_id))} as={Col} variant="secondary" className="mx-2">
                    <img src={Cook_Icon} alt="Cook this Reci" width="35" height="35" />
                  </Button></Link>
                  {/* <Button as={Col} variant="primary"><img src={Nutrition_Icon} alt="See Reci Nutrition Label (Sugars, Carbs, Energy, Fats, and Proteins)" width="35" height="35"/></Button> */}
                  {/* <a href={data.Website_URL? data.Website_URL : data.YouTube_URL}><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /> </Button></a> */}
                  {
                    data.Website_URL && <a href={data.Website_URL} target="_blank"><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button></a>
                  }
                  {/* {logged_user_id && <Button onClick={() => popuphandler(id)} as={Col} variant="primary" >
                    <img src={retweetImg} alt="Website" width="30" height="30" /> </Button>
                  } */}
                  <Button onClick={() => logged_user_id ? popuphandler(id) : navigate('/login')} as={Col} variant="primary" >
                    <img src={copy_recipie} alt="Website" width="30" height="30" />
                  </Button>
                </div>

                <div className="mx-0 text-center">
                  <EmailShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><EmailIcon size={32} round={true} /></EmailShareButton>
                  <FacebookShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><FacebookMessengerIcon size={32} round={true} /></FacebookShareButton>
                  <LinkedinShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                  <PinterestShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} media={data.Website_URL ? data.Website_URL : data.YouTube_URL}><PinterestIcon size={32} round={true} /></PinterestShareButton>
                  <RedditShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><RedditIcon size={32} round={true} /></RedditShareButton>
                  <TumblrShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
                  <TwitterShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                  <WhatsappShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>



      <div id="myModal" className="modal" style={modalstyle}>

        <div className="modal-content">
          <span className="close">&times;</span>
          <div className="modal-body">
            <p>Do you really want to repost in your profile ?</p>
          </div>
          <div className="modal-footer">
            <Button onClick={() => popupClosehandler()} as={Col} variant="primary" className='btn-no'>
              No </Button>
            <Button onClick={() => reposthandler()} as={Col} variant="primary" className='btn-yes' >
              Yes </Button>
          </div>
        </div>

      </div>

    </div >
  );
}

export default ProfileSelected;
