
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate,Link } from "react-router-dom";

import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import ChatBox from "../Components/ChatBox";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  // const [name, setName] = useState("");
  const navigate = useNavigate();

  // const fetchUserName = async () => {
  //   try {
  //     const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
  //     const doc = await getDocs(q);
  //     const data = doc.docs[0].data();

  //     setName(data.name);
  //   } catch (err) {
  //     console.error(err);
  //     // alert("An error occured while fetching user data");
  //   }
  // };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    // fetchUserName();
  }, [user, loading]);
 console.log()
  return (

    <div className={`${user ? 'page-container' : 'web-container'}`}>
      <div className="dashboard">
        {/* <div className="dashboard__container">
          <div className="profile_image"></div>
          <div>{name}</div>
          <div>{user?.email}</div><br/>
          <Link to="/addrecipe">Add Reci</Link>
          <button className="dashboard__btn" onClick={logout}>
            Logout
          </button>
        </div> */}
        Dashboard
      </div>

    </div>
  );
}

export default Dashboard;
