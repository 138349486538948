import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import {db} from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import { Routes, Route, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function ProfileSelected() {

  const [info , setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);

  const opts = {
      height: '600',
      width: '90%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
      },
    };

    const divStyle = {
      paddingTop: '100px'
    };

        const ColoredLine = ({ color }) => (
          <hr
            style={{
              color,
              backgroundColor: color,
              height: .5
            }}
          />
        );

  useEffect(() => {
    db.collection(process.env.REACT_APP_COLLECTION_POSTS).where("user_id", "==", "Reci").onSnapshot((snapshot) => {
      setRecis(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
    console.log({ Recis });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://connect.bingewave.com/connect.js";
    script.async = true;

    document.body.appendChild(script);

    setTimeout(() => { window.BingewaveConnector.init();}, 1000);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div style={divStyle}>
    <Col className="mx-0">
    <span dangerouslySetInnerHTML={{__html: '<bw:widget env="prod" type="stream" id="7efd6a71-4ee1-4d6e-8db7-3c0b85e25e1c"></bw:widget>'}} />
    </Col>
    </div>
  );
}

export default ProfileSelected;
