import logo from './ReciLogo.png';
import './App.css';
import React, { useState, useEffect, Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import YouTube from 'react-youtube';
import GridLayout from 'react-grid-layout';
import GetRecis from './Recis'

import Header from './header.js'
import Header1 from './header1.js'
import Header2 from './header2.js'
import {
  Router,
  Routes, // instead of "Switch"
  Route,
  Link,
  Navigate, useNavigate
} from "react-router-dom";
import SelectedReci from "./ReciSelected"
import Login from './Login.js'
import Logout from './Logout.js'
import Cookbooks from './Cookbooks.js'
import Profiles from './Profiles.js'
import ProfileSelected from './ProfileSelected.js'
import InstagramEmbed from 'react-instagram-embed';
import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import db from './firebase';

function Home() {



  const divStyle = {
    paddingTop: '100px'
  };

  const viewportContext = React.createContext({});

  const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    React.useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
      <viewportContext.Provider value={{ width, height }}>
        {children}
      </viewportContext.Provider>
    );
  };

  const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
  };

  const MobileComponent = () =>

    <div className="web-container">
      <Header2 />
      <GetRecis />
    </div>;

  const DesktopComponent = () =>

    <div className="web-container">
      <Header />
      <GetRecis />


    </div>;

  const MyComponent = () => {
    const { width } = useViewport();
    const breakpoint = 620 + 13; //

    console.log(width)


    return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
  };

  return (

    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}




export default Home;
