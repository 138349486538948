import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import InstaLikeComponent from './InstaLikeComponent'
import ProfileImage from '../images/profile.png'
import { Routes, Route, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
// import Feed from './Feed'

// import './instaFeeds.css'

const InstaVideoFeed = ({token, ...props}) => {
    const [feeds, setFeedsData] = useState([]);
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;
    const divStyle = {
        paddingTop: '100px'
     };
    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost () {
          try{
            axios
                .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=12&access_token=${process.env.REACT_APP_INS_TOKEN}`)
                .then((resp) => {
                    setFeedsData(resp.data.data)
                })
          } catch (err) {
              console.log('error', err)
          }
        }

        // manually call the fecth function 
        fetchInstagramPost();
  
        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort(); 
        };
    }, [props.limit])
    console.log('feeds',feeds);
    return (
        <Grid container spacing={{ xs: 12, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} className="insta_feed_box" style={divStyle}>
            <Grid item xs={12} sm={12} md={8}>
            <div className="insta_container">
               
































            
                <div className='media_btn'>
                <Link to="/feed">
                    Photos
                    </Link>
                    <Link to="/videofeed" className='active'>
                    Video
                    </Link>
                </div>
                <div className='instagram_feed_photo'>
                   <Grid container spacing={{ xs: 12, md: 10 }} columns={{ xs: 12, sm: 12, md: 12 }} >
                    {feeds.map((feed) => (
                    <>
                       {feed.media_type == 'VIDEO'
                        ? (<Grid item xs={12} sm={12} md={3}> <video
                            width='100%'
                            height='auto' 
                            src={feed.media_url} 
                            type="video/mp4" 
                            controls playsinline>
                        </video></Grid> )
                        : null}  
                    </>
                     ))}
                   
                    </Grid>
                </div>
                
                {/* <InstaLikeComponent/> */}
            </div>
            </Grid>
        </Grid>
    );
}

export default InstaVideoFeed;