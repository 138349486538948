import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const Alert = (props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Body>
                <h4 className='title-4'>Are you sure you want to delete this record? </h4>
                <h4 className='title-5'>Once you deleted, you will not be able to recover this data. </h4>
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={props.handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Alert;