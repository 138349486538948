import axios from "axios";
import { useEffect, useState } from "react";
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { insertRecipe, auth, db, logout, storage, addCalanderAccess, getProductById } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import { query, collection, getDocs, where, increment, onSnapshot, doc, getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
import { getAuthRefreshToken, getEvents, insertEvent } from "../utils/googleCalender";
import { Row, Col, Card, InputGroup, Form, Button, ProgressBar, Spinner } from 'react-bootstrap'
import Calendar from 'react-calendar';
import moment from 'moment';
import { generateRandomString, timeSlotsArray } from "../utils/dataUtils";
import CustomSpinner from "./CustomSpinner";
import Loader from "./Loader";

const Booking = () => {

  const [user, loading, error] = useAuthState(auth);
  const [chefProfile, setChefProfile] = useState(null);
  const [chefEvents, setChefEvents] = useState(null);
  const [product, setProduct] = useState(null);
  const today = new Date();

  const [slotList, setSlotList] = useState([]);
  const [timeSlot, setTimeSlot] = useState('');
  const [date, setDate] = useState(today);
  const [step1, setStep1] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const profile_id = queryParams.get('profile');
  const product_id = queryParams.get('product');
  const status = queryParams.get('status');
  const paymentId = queryParams.get('paymentId');

  const [inputField, setInputField] = useState({
    description: '',
    summary: '',

  })
  const [errors, setErrors] = useState({})


  useEffect(() => {
    console.log(user, "user")
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    const isToday = date.toDateString() === new Date().toDateString();

    const availableTimeSlots = timeSlotsArray.filter(item => {
      const [slotHour, slotMinute] = item.split(':').map(Number);
      return isToday ? (slotHour > currentHour || (slotHour === currentHour && slotMinute >= currentMinute)) : true;
    });

    if (chefEvents) {
      const filteredTimeSlots = filterTimeSlots(availableTimeSlots, chefEvents?.items);
      setSlotList(filteredTimeSlots);
    } else {
      setSlotList(availableTimeSlots);

    }

  }, [date, loading, chefEvents]);



  useEffect(() => {
    fetchUserName();
    if (product_id) {
      getProductById(product_id).then(res => {
        console.log(res, "product")
        setProduct(res)
      }).catch(err => {
        console.log(err, "err")
      });
    }
  }, [])


  const fetchUserName = async () => {
    if (profile_id) {

      try {
        const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", profile_id));
        const doc = await getDocs(q);
        const data = { id: doc.docs[0].id, ...doc.docs[0].data() };
        console.log(data, "chefProfile")

        setChefProfile(data);

        if (data.calendarAccess) {
          const chefEvents = await getEvents(data);
          console.log(chefEvents, "chefEvents")
          setChefEvents(chefEvents);
        }
      } catch (err) {
        console.error("Error fetching document:", err);
        throw err; // Throw error for error handling further up the chain
      }
    }
  };

  const getProductDetail = () => {

  }


  useEffect(async () => {


    console.log(paymentId, "paymentId")

    if (status === 'success' && paymentId) {
      const docRef = doc(db, process.env.REACT_APP_COLLECTION_STRIPE_PAYMENTS, paymentId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        const data = { ...docSnap.data(), id: docSnap.id };

        console.log(data, "data")

        if (data.payment_status === 'paid' && data.eventBooking_status === false) {


          let eventData = localStorage.getItem('eventData')
          let paylaod = { ...JSON.parse(eventData), paymentId };
          console.log(paylaod, "paylaod")

          if (paylaod) {
            // setPageLoading(true);
            const response = await insertEvent(paylaod);
            console.log(response, "response")
            if (response === 1) {
              setPageLoading(false);
              toast.success('Payment Successfull, Booking scheduled sucessfully!')
              navigate('/bookedEvents')
            } else {
              setPageLoading(false);
              toast.success('Booking is not scheduled!')
            }
          }
          setPageLoading(false);
        } else {
          paymentFailure('A booking has already been scheduled');
        }
      } else {
        paymentFailure('Payment Not Completed');
      }
    } else if (status === 'failure' && paymentId) {
      paymentFailure('Payment Not Completed');
    }

  }, [status])

  const paymentFailure = (Message) => {
    setPageLoading(false);
    toast.error(Message)
    localStorage.setItem('eventData', '');
    navigate('/Profiles')

  }

  const filterTimeSlots = (availableTimeSlots, events) => {
    let filteredTimeSlots = [...availableTimeSlots];

    console.log(filteredTimeSlots, "filteredTimeSlots", events);
    if (events?.length > 0) {
      events?.forEach(event => {
        const eventStart = new Date(event?.start?.dateTime);
        const eventEnd = new Date(event?.end?.dateTime);

        filteredTimeSlots = filteredTimeSlots?.filter(slot => {
          const [slotHour, slotMinute] = slot.split(':').map(Number);
          const slotTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), slotHour, slotMinute);

          return !(slotTime >= eventStart && slotTime < eventEnd);
        });
      });
    }

    return filteredTimeSlots;
  }



  const onDateChange = (e) => {
    setDate(e);
    setTimeSlot('');

  }

  function convert12HourFormat(time24) {
    const [hours, minutes] = time24.split(':').map(Number);

    let hour12 = hours % 12 || 12;

    let suffix = hours < 12 ? 'am' : 'pm';

    return `${hour12}:${minutes < 10 ? '0' + minutes : minutes}${suffix}`;
  }

  const getEndTimeSlot = (startTimeSlot) => {
    return moment(startTimeSlot, 'h:mm a').add(product?.hours, 'hours').add(product?.minutes, 'minutes').format('h:mma');
  }

  const inputsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target; // Destructure name and value directly from e.target

    setInputField({ ...inputField, [name]: value });
    setErrors({ ...errors, [name]: '' });
  }

  const submitDate = (e) => {
    console.log(e, "event")
    console.log(timeSlot, "timeSlot")
    setStep1(true);

  }

  const goStep1 = () => {
    setStep1(false);
  }


  const convertDateTimeString = (io_date, io_timeSlot) => {
    const momentDate = moment(io_date);

    const momentTime = moment(io_timeSlot, 'h:mma');

    const combinedDateTime = momentDate.format('YYYY-MM-DD') + 'T' + momentTime.format('HH:mm:ss') + momentDate.format('Z');
    return combinedDateTime;
  }

  const findErrors = () => {
    const { summary, description } = inputField;

    const newErrors = {}

    if (!summary || summary === '') newErrors.summary = 'Meeting summary is required!'
    if (!description || description === '') newErrors.description = 'Description is required!'

    return newErrors
  }



  const scheduleMeet = async (e) => {
    e.preventDefault();
    setUploading(true);

    const newErrors = findErrors();
    console.log(newErrors, "newErrors");

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setUploading(false);

    } else {


      const momentDate = moment(date);
      const formattedDateTime = momentDate.format('YYYY-MM-DDTHH:mm:ssZ');


      var event = {
        'summary': inputField.summary,
        // 'location': '800 Howard St., San Francisco, CA 94103',
        'description': inputField.description,
        'start': {
          'dateTime': convertDateTimeString(date, convert12HourFormat(timeSlot)),
          'timeZone': 'Asia/Kolkata'
        },
        'end': {
          'dateTime': convertDateTimeString(date, getEndTimeSlot(timeSlot)),
          'timeZone': 'Asia/Kolkata'
        },
        'conferenceData': {
          'createRequest': {
            'requestId': generateRandomString(),
            'conferenceSolutionKey': {
              'type': 'hangoutsMeet'
            },
          }
        },
        // 'recurrence': [
        //   'RRULE:FREQ=DAILY;COUNT=2'
        // ],
        'attendees': [
          { 'email': user.email },
        ],
        'reminders': {
          'useDefault': false,
          'overrides': [
            { 'method': 'email', 'minutes': 24 * 60 },
            { 'method': 'popup', 'minutes': 10 }
          ]
        }
      };

      let eventData = {
        event,
        user_id: user.uid,
        product_id: product_id,
        vendor: chefProfile
      }

      localStorage.setItem('eventData', JSON.stringify(eventData));

      Payment();

    }
  }


  const Payment = async () => {
    let payload = {
      user_id: user.uid,
      vendor_id: chefProfile.uid,
      stripeAccountId: chefProfile.stripeAccountId,
      product: product_id,
      amount: product.price,
      currency: product.currency,
      price_id: product.price_id
    }
    return await axios.post(`${process.env.REACT_APP_API_URL}/stripe/createCheckout`, payload)
      .then(res => {

        if (res.status === 200) {
          setUploading(false);
          toast.success('Checkout session created sucessfully!');
          window.location.href = res.data.session.url;

        } else {
          setUploading(false);
          toast.error('Checkout session is not created!')
        }
      })
      .catch(err => {
        console.log(err, "err");
        setUploading(false);
        toast.error('Checkout session is not created!')
      });

  }




  return (
    <div className='web-container'>
      {/* {pageLoading ? <div className="spinner-container"><Spinner animation="border" className="page-spinner" /> <div>Processing...</div> </div>  : */}
      {pageLoading && <Loader message="Processing" />}
      <Card className="card-container shadow booking-page">

        <Row className={`${step1 ? 'd-none' : ''}`}>
          <Col md={4} className='box-container'>
            <i className="fa fa-arrow-left mb-4" aria-hidden="true" onClick={() => navigate(-1)}></i>

            <h1 className="title-1">Book a Meal with {chefProfile?.name}</h1>
            <p className="mt-4">
              <i class="fa fa-envelope-o" aria-hidden="true"></i> Chef Email: {chefProfile?.email}
            </p>
            <p className="mt-2">
              <i class="fa fa-cc-stripe" aria-hidden="true"></i> Booking: {product?.name}
            </p>
            <div className="mt-2">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              {product?.hours == 1 ? product?.hours + 'Hour ' : product?.hours > 1 ? product?.hours + 'Hours ' : ""}
              {product?.minutes == 1 ? product?.minutes + 'Min ' : product?.minutes > 1 ? product?.minutes + 'Mins ' : ""}
            </div>
            <p className="mt-2">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i> $ {product?.price}
            </p>
            <div className="mt-2">
              <i class="fa fa-video-camera" aria-hidden="true"></i> Web conferencing details provided upon confirmation.
            </div>
          </Col>
          <Col md={8} className='box-container'>
            <Row>
              <Col md={8} >
                <h2 className="title-2">Select a Date & Time</h2>
                <Calendar className="booking-calendar" onChange={onDateChange} value={date} minDate={today} />
              </Col>
              <Col md={4} >
                <p>{moment(date).format('dddd, MMMM D')}</p>

                <div className="time-slot-container  mt-4">
                  {
                    slotList.length ? slotList.map(item => (
                      <div className={`time-slot ${timeSlot === item ? 'active' : ''}`} onClick={() => setTimeSlot(item)}> {convert12HourFormat(item)} - {getEndTimeSlot(item)} </div>
                    )) : 'No available slots'
                  }
                </div>

                {slotList.length ?
                  <button className='theme-button mt-4' onClick={submitDate} disabled={timeSlot === ''} >Next</button> : ""}

              </Col>
            </Row>

          </Col>
        </Row>


        <Row className={`${!step1 ? 'd-none' : ''}`}>
          <Col md={6} className='box-container'>

            <i className="fa fa-arrow-left mb-4" aria-hidden="true" onClick={() => setStep1(false)}></i>

            <h1 className="title-1">Book a Meal with {chefProfile?.name}</h1>

            <p className="mt-4">
              <i class="fa fa-envelope-o" aria-hidden="true"></i> Chef Email: {chefProfile?.email}
            </p>
            <p className="mt-2">
              <i class="fa fa-cc-stripe" aria-hidden="true"></i> Booking: {product?.name}
            </p>
            <div className="mt-2">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              {product?.hours == 1 ? product?.hours + 'Hour ' : product?.hours > 1 ? product?.hours + 'Hours ' : ""}
              {product?.minutes == 1 ? product?.minutes + 'Min ' : product?.minutes > 1 ? product?.minutes + 'Mins ' : ""}
            </div>
            <p className="mt-2">
              <i class="fa fa-calendar" aria-hidden="true"></i>  {convert12HourFormat(timeSlot)} - {getEndTimeSlot(timeSlot)}, <br /> {moment(date).format('dddd, MMMM DD, YYYY')}
            </p>
            <p className="mt-2">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>$ {product?.price}
            </p>
            <p className="mt-2">
              <i class="fa fa-globe" aria-hidden="true"></i> Eastern Standard Time
            </p>
          </Col>
          <Col md={6} className='box-container'>
            <h2 className="title-2">Details</h2>
            <Form.Group as={Col} md="12" className="mb-2" controlId="validationCustom02">
              <Form.Label>Name: {user?.displayName} </Form.Label>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2" controlId="validationCustom02">
              <Form.Label>Email: {user?.email} </Form.Label>
            </Form.Group>



            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Label>Booking Summary *</Form.Label>
              <Form.Control
                required
                type="text"
                name="summary"
                value={inputField.summary}
                placeholder="Booking Summary "
                onChange={inputsHandler}
                isInvalid={!!errors.summary}

              />
              <Form.Control.Feedback type="invalid">
                {errors.summary}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Label>Meal Description *</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                name="description"
                value={inputField.description}
                placeholder="Describe the booking event. Mention the Recis to cook, decide on the menu with your chef."
                onChange={inputsHandler}
                isInvalid={!!errors.description}

              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <button className='theme-button mt-4' onClick={scheduleMeet} disabled={uploading}>Pay & Schedule
              <CustomSpinner status={uploading} />
            </button>

          </Col>
        </Row>

      </Card>

    </div>
  );
}

export default Booking;
