import React, { useState, useEffect } from 'react';
import { insertRecipe, auth, db, logout, storage, addCalanderAccess, deleteProduct, getProducts } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import axios from 'axios'
import { query, collection, getDocs, where, increment, onSnapshot } from "firebase/firestore";
import CustomSpinner from './CustomSpinner';
import ProductForm from './ProductForm';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { IconButton, ListItemIcon } from '@mui/material';
import Alert from './Alert';
import { useNavigate, Link, useParams } from "react-router-dom";

const Products = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [action, setAction] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [alert, setAlert] = useState(false);
    const params = useParams();

    console.log(params, "params")

    const closeAlert = () => setAlert(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        fetchUserName();
    }, [user, loading]);





    const fetchUserName = async () => {
        try {
            const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setUserData({ ...data, id: doc.docs[0].id });

            console.log(data?.stripeAccountId)


            if (data?.stripeAccountId) {
                fetchProducts(data?.stripeAccountId);
            }


        } catch (err) {
            console.error(err);
        }
    };

    const fetchProducts = async (stripeAccountId) => {
        getProducts(stripeAccountId).then(res => {
            console.log(res, "res")
            setProducts(res);
        })
            .catch(err => {
                console.log(err)
            })
    }

    console.log(products, "products")

    const createAccount = (e) => {
        e.preventDefault();
        setUploading(true);

        console.log(userData)

        let payload = { email: userData.email, user_id: userData.id }

        axios.post(`${process.env.REACT_APP_API_URL}/stripe/createAccount`, payload)
            .then(res => {
                console.log(res)
                window.location.href = res.data.accountLink.url;
                setUploading(false);


            })
            .catch(err => {
                console.log(err, "err");
                setUploading(false);
            });
    }

    const addProduct = () => {
        setModalShow(true);
        setAction('add');
    }

    const onEdit = (item) => {
        setModalShow(true);
        setProduct(item);
        setAction('edit');

    }

    const confirmDelete = (item) => {
        setAlert(true);
        setProduct(item);
    }



    const onDelete = async () => {
        setAlert(false);

        let payload = {
            id: product.id,
            price_id: product.price_id,
            product_id: product.product_id,
            stripeAccountId: product.stripeAccountId
        }
        console.log(payload, product, "payload")

        axios.delete(`${process.env.REACT_APP_API_URL}/stripe/deleteProduct`,  { data : payload })
            .then(res => {
                console.log(res)

                if (res.status === 200) {
                    toast.success('Booking product deleted sucessfully!')
                    setProduct(null);
                    fetchProducts(userData?.stripeAccountId);
                } else {
                    setUploading(false);
                    toast.success('Booking product is not deleted!')
                }

            })
            .catch(err => {
                console.log(err, "err");
                setUploading(false);
                toast.error('Booking product is not updated!')
            });



    }


    const onHide = () => {
        setAction(null);
        setProduct(null);
        setModalShow(false);
        fetchProducts(userData?.stripeAccountId);

    }

    return (
        <div className={`${user ? 'page-container' : 'web-container'}`}>

            <Alert show={alert} handleClose={closeAlert} handleConfirm={onDelete} />

            <ProductForm
                show={modalShow}
                onHide={onHide}
                userData={userData}
                product={product}
                action={action}

            />

            {(userData !== null && userData?.stripeAccount && !params.status) ?
                <div className='d-flex justify-content-between mb-2 '>
                    <h1 className='title-2'> Booking Products </h1>
                    <button className='theme-button ' onClick={addProduct}>Add Booking Product <i class="fa fa-plus" ></i></button>
                </div>
                : ""}


            {(userData !== null && !userData?.stripeAccount) ?
                <div className='stripe-container'>
                    <p className='title-3'> Connect your vendor account to create a booking product </p>

                    <button className='theme-button' style={{ width: '200px' }} onClick={createAccount} disabled={uploading}>
                        <i class="fa fa-cc-stripe" aria-hidden="true"></i> Connect Account
                        <CustomSpinner status={uploading} />
                    </button>

                </div> : ""}

            {(params.status === 'account_success' && !products?.length) ?
                <div className='stripe-container'>
                    <p className='title-3'>  Your vendor account has been successfully created!</p>
                    <p className='title-2'>  Begin adding your booking products now. </p>

                    <button className='theme-button ' onClick={addProduct}>Add Product <i class="fa fa-plus" ></i></button>

                </div> : ""}

                {(params.status === 'account_failure' && !products?.length) ?
                <div className='stripe-container'>
                    <p className='title-3'>  Your vendor account is not created!</p>
                    <p className='title-2'>  Try again to connect your vendor account with Stripe</p>

                    <button className='theme-button' style={{ width: '200px' }} onClick={createAccount} disabled={uploading}>
                        <i class="fa fa-cc-stripe" aria-hidden="true"></i> Connect Account
                        <CustomSpinner status={uploading} />
                    </button>

                </div> : ""}

            <Row>
                {products !== null ?

                    products.map((item, index) => (
                        <Col md={6} lg={4} xl={3}>

                            <Card className="product-card">
                                <Card.Body>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <p className="title-4"> {item.name} </p>
                                            <Dropdown className="custom-dropdown">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <i class="fa fa-ellipsis-v" aria-hidden="true" ></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={(e) => onEdit(item)}>
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => confirmDelete(item)}>
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>


                                        </div>
                                        <p className='title-5 mb-4'> {item.description.length > 150 ? item.description.substring(0, 150) + '...' : item.description} </p>
                                    </div>
                                    <div className='d-flex justify-content-between '>
                                        <p className='title-5'>
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                            {item.hours == 1 ? item.hours + 'Hour ' : item.hours > 1 ? item.hours + 'Hours ' : ""}
                                            {item.minutes == 1 ? item.minutes + 'Min ' : item.minutes > 1 ? item.minutes + 'Mins ' : ""}
                                        </p>
                                        <p> ${item.price} </p>
                                    </div>


                                </Card.Body>
                            </Card>

                        </Col>


                    ))

                    : ""}
            </Row>
        </div>
    )
}

export default Products;
