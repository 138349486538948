import { Spinner } from 'react-bootstrap';

const CustomSpinner = ({status}) => {

    return(
        <>
        {
            status ?
                <Spinner animation="border" role="status" className='custom-spinner'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                : ""
        }
        </>
        
    )

}

export default CustomSpinner;