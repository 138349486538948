import React, { useState, useEffect } from 'react';
import { insertRecipe, auth, db, logout, storage, addCalanderAccess, deleteProduct, getProducts } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import axios from 'axios'
import { query, collection, getDocs, where, increment, onSnapshot, doc, getDoc } from "firebase/firestore";
import CustomSpinner from '../CustomSpinner';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { IconButton, ListItemIcon } from '@mui/material';
import Alert from '../Alert';
import { Routes, Route, Link, useParams, useLocation, useNavigate } from "react-router-dom";

const BookProducts = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [products, setProducts] = useState(null);
    const [chefProfile, setChefProfile] = useState(null);
    const [chefProducts, setChefProducts] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const profile_id = queryParams.get('profile');

    useEffect(() => {

        fetchUserName();
    }, []);

    const fetchUserName = async () => {
        if (profile_id) {
            console.log(profile_id, "profile_id")

            try {

                const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", profile_id));
                const doc = await getDocs(q);
                const data = doc.docs[0].data();

                console.log(data, "chefProfile")
                setChefProfile(data);

                if (data.stripeAccount) {
                    fetchProducts(data?.stripeAccountId);
                }

            } catch (err) {
                console.error("Error fetching document:", err);
                throw err; // Throw error for error handling further up the chain
            }
        }
    };

    const fetchProducts = async (stripeAccountId) => {
        getProducts(stripeAccountId).then(res => {
            console.log(res, "products")
            setChefProducts(res);
        })
            .catch(err => {
                console.log(err)
            })
    }

    const onBooking = (id) => {

        localStorage.setItem('eventData', '');
        navigate(`/booking?profile=${profile_id}&product=${id}`)

    }

    console.log(chefProducts, "products")



    return (
        <div className='web-container'>

            <div className='d-flex justify-content-between mb-2 '>
                <h1 className='title-2'> Book a Meal </h1>
            </div>

            <Row>
                {chefProducts !== null ?

                    chefProducts.map((item, index) => (
                        <Col md={6} lg={4} xl={3}>

                            <Card className="product-card" onClick={() => onBooking(item.id)}>
                                <Card.Body>
                                    <div>
                                        <p className="title-4 mb-2"> {item.name} </p>
                                        <p className='title-5 mb-4'> {item.description.length > 150 ? item.description.substring(0, 150) + '...' : item.description} </p>
                                    </div>
                                    <div className='d-flex justify-content-between '>
                                        <p className='title-5'>
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                            {item.hours == 1 ? item.hours + 'Hour ' : item.hours > 1 ? item.hours + 'Hours ' : ""}
                                            {item.minutes == 1 ? item.minutes + 'Min ' : item.minutes > 1 ? item.minutes + 'Mins ' : ""}
                                        </p>
                                        <p> ${item.price} </p>
                                    </div>

                                </Card.Body>
                            </Card>

                        </Col>


                    ))

                    : <div className="text-center"> No meals found... </div>}
            </Row>
        </div>
    )
}

export default BookProducts;
