import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Grid from '@material-ui/core/Grid';
import { insertRecipe, updateRecipe, auth, db, logout, storage } from "../../firebase";
import { query, collection, getDocs, where, increment, onSnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import NutritionComponent from './AddRecipeFormComponent/NutritionComponent';
import IngredientsComponent from './AddRecipeFormComponent/IngredientsComponent';
import StepListForm from './AddRecipeFormComponent/StepListForm';
import uploadIcon from '../../images/upload.png';
import YouTubeIcon from '../../images/YouTube.png';
import VideoUploadComponent from './AddRecipeFormComponent/VideoUpload'
import { Row, Col, InputGroup, Form, Button, ProgressBar } from 'react-bootstrap';
import { reciCategories } from "../../utils/dataUtils";
import Ingredients from "./AddRecipeFormComponent/Ingredients";
import Instructions from "./AddRecipeFormComponent/Instructions";
import ReactPlayer from 'react-player'
import { ToastContainer, toast } from 'react-toastify';

function AddRecipeComponent() {

   const [user, loading, error] = useAuthState(auth);
   const navigate = useNavigate();
   const route = useLocation();
   const [name, setName] = useState("");
   const [file, setFile] = useState("");
   const [uploading, setUploading] = useState(false);
   const [status, setStatus] = useState(1);
   const [action, setAction] = useState('add');
   const params = useParams();
   console.log(params, "params")

   const [videotype, setvideotype] = useState(false);
   const [percent, setPercent] = useState(0);

   const [toDoStepList, setToDoStepList] = useState('');

   const [inputField, setInputField] = useState({
      own_recipe: '1',
      recipe_cuisine: '',
      recipe_title: '',
      website_url: '',
      youtube_url: '',
      cook_time: '',
      author: '',
      profile_URL: '',
      reci_URL: '',
      carbs_amount: '',
      carbs_units: '',
      energy_amount: '',
      energy_units: '',
      fat_amount: '',
      fat_units: '',
      protein_amount: '',
      protein_units: '',
      sugars_amount: '',
      sugar_units: '',
      serving_size: '',
      Video: route?.state?.AIcooking ? 'Upload' : 'YouTube',
      Videofile: route?.state?.AIurl ? route?.state?.AIurl : ""
   })
   const [errors, setErrors] = useState({})
   const [ingredients, setIngredients] = useState([
      {
         id: 1,
         Ingredient: '',
         Amount: '',
         Units: '',
      }
   ]);

   const [steps, setSteps] = useState([
      {
         id: 1,
         step: '',
      }
   ]);

   useEffect(() => {
      console.log(user, "user")
      if (loading) return;
      if (!user) return navigate("/login");
      fetchUserName();
   }, [user, loading]);

   const radioHandler = (e) => {
      console.log(e.target.value);
      setStatus(e.target.value);
      setInputField({ ...inputField, ['own_recipe']: e.target.value })
   };

   useEffect(() => {
      if (route?.state?.AIcooking) {
         let data = route?.state?.AIdata;
         if (data) {
            setInputField({
               ...inputField,
               author: data["Author Info"],
               recipe_cuisine: data["Select Reci Cuisine"],
               recipe_title: data["Describe this Reci"][0]["Enter Reci Title"],
               cook_time: data["Describe this Reci"][0]["Minimum Cooking Time in Minutes"],
               Video: 'Upload',
               Videofile: route?.state?.AIurl ? route?.state?.AIurl : ""
            })
         }
         if (data["Ingredients"]?.length > 0) {
            var fetchData = data["Ingredients"]?.map((item, i) => {
               return {
                  id: i + 1,
                  Ingredient: item?.Ingredient,
                  Amount: item?.Amount,
                  Units: item?.Unit,
               }
            })
            setIngredients(fetchData)
         }

         if (data["Steps"]) {
            const transformedSteps = Object.entries(data["Steps"]).map(([key, value], index) => ({
               id: index + 1,
               step: value
            }));
            // Set the transformed steps into the state
            setSteps(transformedSteps);
         }
         setPercent(100);
      }
   }, [route?.state?.AIcooking, route?.state?.AIdata])

   const fetchUserName = async () => {
      try {
         const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
         const doc = await getDocs(q);
         const data = doc.docs[0].data();
         console.log(data, "user data")
         setName(data.name);
         setInputField({
            ...inputField, 'profile_pic': data.profile_pic,
            'userdoc_id': doc.docs[0].id,
            user_id: doc.docs[0].id,
            'profile_URL': '',
            'author': data?.name,
            'reci_URL': '#',
         })
      } catch (err) {
         console.error(err);
      }

      if (params.id) {
         setAction('edit');
         const q2 = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
         onSnapshot(q2, (querySnapshot) => {
            let reci = querySnapshot.docs.map(doc => {
               if (doc.id === params.id) {
                  let data = doc.data();
                  console.log(data, "data")
                  setInputField({
                     ...inputField,
                     id: doc.id,
                     profile_pic: data.profile_pic,
                     userdoc_id: data.userdoc_id,
                     own_recipe: data.own_recipe,
                     recipe_cuisine: data.Cuisine,
                     recipe_title: data.Title,
                     website_url: data.Website_URL,
                     serving_size: data.serving_size,
                     youtube_url: data.Type === 'YouTube' ? data.YouTube_URL : '',
                     cook_time: data.min_cook,
                     author: data.author[0].Name,
                     profile_URL: data.author[0].profile_URL,
                     reci_URL: data.author[0].reci_URL,
                     carbs_amount: data.Nutrition.Carbs.Amount,
                     carbs_units: data.Nutrition.Carbs.Units,
                     energy_amount: data.Nutrition.Carbs.Amount,
                     energy_units: data.Nutrition.Carbs.Units,
                     fat_amount: data.Nutrition.Energy.Amount,
                     fat_units: data.Nutrition.Energy.Units,
                     protein_amount: data.Nutrition.Protein.Amount,
                     protein_units: data.Nutrition.Protein.Units,
                     sugars_amount: data.Nutrition.Sugars.Amount,
                     sugar_units: data.Nutrition.Sugars.Units,
                     Video: data.Type,
                     Videofile: data.Type === 'Upload' ? data.YouTube_URL : '',
                  })
                  setIngredients(data.Reci.Ingredients);
                  let steps = data.Reci.Steps.map((item, i) => {
                     return { id: i + 1, step: item }
                  })
                  setSteps(steps);

               }
            })

            console.log(reci, "reci")
         })
      }

   };



   const inputsHandler = (e) => {
      e.preventDefault();
      let sanitizedValue = e.target.value;

      let numberItems = ['carbs_amount', 'energy_amount', 'fat_amount', 'sugars_amount', 'protein_amount', 'cook_time']
      if (numberItems.includes(e.target.name)) {
         sanitizedValue = e.target.value.replace(/[^\d.]/g, '');

         const dotIndex = sanitizedValue.indexOf('.');
         if (dotIndex !== -1 && sanitizedValue.indexOf('.', dotIndex + 1) !== -1) {
            sanitizedValue = sanitizedValue.substring(0, dotIndex + 1) + sanitizedValue.substring(dotIndex + 1).replace(/\./g, '');
         }
      }

      setInputField({ ...inputField, [e.target.name]: sanitizedValue });
      setErrors({ ...errors, [e.target.name]: '' });
   }



   // const regex = new RegExp('^(https?:\/\/)?([\\w\\d-]+\\.){1,}[\\w-]+(\/[\\w-]+)*$');

   const regex = new RegExp(
      '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
      'i'
   );

   const unitRegex = new RegExp('^[^\d]*[a-zA-Z][^\d]*([a-zA-Z]?[^\d]*)?$');


   const findErrors = () => {
      const { own_recipe, author, profile_URL, recipe_cuisine, recipe_title, website_url, cook_time, serving_size, video, youtube_url, Videofile, Video } = inputField;
      console.log(inputField, "data", Video)

      const newErrors = {}

      if (own_recipe == '2' && (!author || author === '')) newErrors.author = 'Author name is required!'
      if (own_recipe == '2' && (!profile_URL || profile_URL === '')) newErrors.profile_URL = 'Profile url is required!'
      if (!recipe_cuisine || recipe_cuisine === '') newErrors.recipe_cuisine = 'Please Select Cuisine!'
      if (!recipe_title || recipe_title === '') newErrors.recipe_title = 'Recipe title is required!'
      if (!cook_time || cook_time === '') newErrors.cook_time = 'Cook time is required!'
      if (!serving_size || serving_size === '') newErrors.serving_size = 'Serving size is required!'

      // if (own_recipe == '2' && (!website_url || website_url === '')) newErrors.website_url = 'Website url is required!'
      // else if (own_recipe == '2' && !regex.test(website_url)) newErrors.website_url = 'Website url is not valid!'

      if (Video === 'YouTube' && (!youtube_url || youtube_url === '')) newErrors.youtube_url = 'Youtube url is required!'
      else if (Video === 'YouTube' && !regex.test(youtube_url)) newErrors.youtube_url = 'Youtube url is not valid!'

      if (Video === 'Upload' === true && !Videofile) newErrors.Videofile = 'Please upload video!'

      newErrors.ingredients = {};
      ingredients.map(item => {
         if (item.id) {
            console.log(unitRegex.test(item.Units), item.Units, "tset")

            newErrors.ingredients[item.id] = {};
            if (!item.Ingredient || item.Ingredient === '') newErrors.ingredients[item.id]['Ingredient'] = 'Ingredient is required!'
            if (!item.Amount || item.Amount === '') newErrors.ingredients[item.id]['Amount'] = 'Amount is required!'
            // else if (!/^\d+$/.test(item.Amount)) newErrors.ingredients[item.id]['Amount'] = 'Amount must be a number!'
            // if (!item.Units || item.Units === '') newErrors.ingredients[item.id]['Units'] = 'Unit is required!'
            // else if (!unitRegex.test(item.Units)) newErrors.ingredients[item.id]['Units'] = 'Unit is not valid!'
         }

      })

      newErrors.steps = {};
      steps.map(item => {
         if (item.id) {
            newErrors.steps[item.id] = {};
            if (!item.step || item.step === '') newErrors.steps[item.id]['step'] = 'Please enter instruction!'

         }

      })


      return newErrors
   }


   const handleSubmit = async e => {
      e.preventDefault();
      setUploading(true);
      console.log("button")

      const newErrors = findErrors();
      console.log(newErrors, "newErrors");

      const ingredientsErrorsPresent = Object.keys(newErrors.ingredients)
         .filter((key) => Object.keys(newErrors.ingredients[key]).length > 0)
         .length > 0;

      const stepsErrorsPresent = Object.keys(newErrors.steps)
         .filter((key) => Object.keys(newErrors.steps[key]).length > 0)
         .length > 0;

      console.log(ingredientsErrorsPresent, Object.keys(newErrors).length, stepsErrorsPresent)

      if (Object.keys(newErrors).length > 2 || ingredientsErrorsPresent || stepsErrorsPresent) {
         setErrors(newErrors);
         setUploading(false);

      } else {
         // alert('Thank you for your feedback!');
         let stepList = [];
         steps.map(item => stepList.push(item.step));
         let data = { ...inputField, Ingredients: ingredients, Steps: stepList };

         console.log(data, inputField, "data");
         if (action === 'add') {
            insertRecipe(data).then(res => {
               console.log(res, "res")
               toast.success(res.message);
               navigate(`/SelectedReci/${res.id}`)
               setUploading(false);
            }).catch(err => {
               toast.success(err.message)
               setUploading(false);
            })

         } else {
            updateRecipe(data).then(res => {
               toast.success(res.message);
               navigate('/myRecipes')
               setUploading(false);
            }).catch(err => {
               toast.success(err.message)
               setUploading(false);
            })
         }
      }
   }


   const VideoTypeURlClick = () => {
      // setvideotype(false);
      setInputField({ ...inputField, 'Video': 'YouTube' });
      setErrors({ ...errors, Videofile: '', youtube_url: '' })

   };
   const VideoTypeUploadClick = () => {
      // setvideotype(true);
      setInputField({ ...inputField, 'Video': 'Upload' })
      setErrors({ ...errors, Videofile: '', youtube_url: '' })

   };

   function handleChange(event) {
      setFile(event.target.files[0]);
   }

   const handleUpload = (e) => {
      e.preventDefault();

      if (!file) {
         setErrors({ ...errors, Videofile: 'Please upload video!' })
         return
      }

      setUploading(true);
      const storageRef = ref(storage, `/files/${file.name}`);

      // progress can be paused and resumed. It also exposes progress updates.
      // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const percent = Math.round(
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            setPercent(percent);
            setUploading(false);

         },
         (err) => console.log(err),
         () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
               console.log(url);
               setInputField({ ...inputField, 'Videofile': url })
               setErrors({ ...errors, Videofile: '' })
               setUploading(false);

            });
         }
      );
   };

   return (
      <div className={`${user ? 'page-container' : 'web-container'}`}>
         <div className='dashboard_main'>
            <Grid container spacing={{ xs: 12, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
               <Grid item xs={12} sm={12} md={8} className="dashboard_main_grid" >

                  <Form noValidate  >


                     <div className="recipe_main-heading "><h4>Author Info</h4></div>

                     <Row>

                        <Form.Group as={Col} md="4" className="mb-3" controlId="own_recipe">
                           <Form.Select name="own_recipe" value={inputField.own_recipe} onChange={radioHandler}
                              isInvalid={!!errors.own_recipe} required>
                              <option value="1">Myself</option>
                              <option value="2">Someone Else</option>
                           </Form.Select>
                        </Form.Group>

                        {status != 1 ? (
                           <Form.Group as={Col} md="4" className="mb-3" controlId="author">
                              <Form.Control
                                 required
                                 type="text"
                                 name="author"
                                 placeholder="Author Name *"
                                 value={inputField.author}
                                 onChange={inputsHandler}
                                 isInvalid={!!errors.author}
                              />
                              <Form.Control.Feedback type="invalid">
                                 {errors.author}
                              </Form.Control.Feedback>
                           </Form.Group>


                        ) : ('')}

                        {status != 1 ? (
                           <Form.Group as={Col} md="4" className="mb-3" controlId="profileUrl">
                              <Form.Control
                                 required
                                 type="text"
                                 name="profile_URL"
                                 placeholder="Social Media Profile URL *"
                                 value={inputField.profile_URL}
                                 onChange={inputsHandler}
                                 isInvalid={!!errors.profile_URL}
                              />
                              <Form.Control.Feedback type="invalid">
                                 {errors.profile_URL}
                              </Form.Control.Feedback>
                           </Form.Group>
                        ) : ('')}

                     </Row>



                     <div className="recipe_main-heading">
                        <h4>Select Reci Cuisine</h4>
                     </div>
                     <Row>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="recipe_cuisine" >
                           <Form.Select name="recipe_cuisine" value={inputField.recipe_cuisine} onChange={inputsHandler}
                              isInvalid={!!errors.recipe_cuisine}
                              required
                           >
                              <option value="" disabled>Select Reci Cuisine *</option>
                              {
                                 reciCategories.map(data => (
                                    <option value={data.catlink}>{data.catlabel}</option>
                                 ))
                              }
                           </Form.Select>
                           <Form.Control.Feedback type="invalid">
                              {errors.recipe_cuisine}
                           </Form.Control.Feedback>
                        </Form.Group>

                     </Row>

                     <div className="recipe_main-heading">
                        <h4>Describe this Reci</h4>
                     </div>

                     <Row>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                           <Form.Control
                              required
                              type="text"
                              name="recipe_title"
                              value={inputField.recipe_title}
                              placeholder="Enter Reci Title *"
                              onChange={inputsHandler}
                              isInvalid={!!errors.recipe_title}

                           />
                           <Form.Control.Feedback type="invalid">
                              {errors.recipe_title}
                           </Form.Control.Feedback>
                        </Form.Group>
                        <input type="hidden" name="user_id" value={user?.uid} />
                     </Row>

                     {/* {inputField.own_recipe == '2' ? */}
                     <Row>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                           <Form.Control
                              // required
                              type="text"
                              name="website_url"
                              value={inputField.website_url}
                              placeholder="Reci Website"
                              onChange={inputsHandler}
                           // isInvalid={!!errors.website_url}
                           />
                           {/* <Form.Control.Feedback type="invalid">
                                 {errors.website_url}
                              </Form.Control.Feedback> */}
                        </Form.Group>
                     </Row>
                     {/* : ""} */}

                     <Row>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                           <Form.Control
                              required
                              type="text"
                              name="serving_size"
                              value={inputField.serving_size}
                              placeholder="Enter Serving Size *"
                              onChange={inputsHandler}
                              isInvalid={!!errors.serving_size}

                           />
                           <Form.Control.Feedback type="invalid">
                              {errors.serving_size}
                           </Form.Control.Feedback>
                        </Form.Group>
                     </Row>

                     <Row>
                        <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                           <Form.Control
                              required
                              type="text"
                              name="cook_time"
                              value={inputField.cook_time}
                              placeholder="Minimum Cooking Time in Minutes *"
                              onChange={inputsHandler}
                              isInvalid={!!errors.cook_time}

                           />
                           <Form.Control.Feedback type="invalid">
                              {errors.cook_time}
                           </Form.Control.Feedback>
                        </Form.Group>
                     </Row>




                     <div className='form_row video_field'>
                        <div className="recipe_main-heading">
                           <h4>Reci Video</h4>
                        </div>
                        {!route?.state?.AIcooking && (
                           <div className="img_list" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'}}>
                              <img src={YouTubeIcon} onClick={VideoTypeURlClick} />
                              <img onClick={VideoTypeUploadClick} src={uploadIcon} />
                           </div>
                        )}
                        {/*
                     {inputField.Video === 'Upload' ? <div className="top-mar">
                        <Form.Group as={Col} md="12" className="mb-3 d-flex justify-content-center" controlId="validationCustom02">
                           <div className="file-upload-custom">

                              <Form.Control
                                 required
                                 type="file"
                                 placeholder="Upload File *"
                                 onChange={handleChange}
                                 accept="video/mp4,video/x-m4v,video/*"
                                 isInvalid={!!errors.Videofile}

                              />
                              <button onClick={handleUpload} className="upload__btn">Upload</button>

                              <Form.Control.Feedback type="invalid">
                                 {errors.Videofile}
                              </Form.Control.Feedback>

                           </div>



                        </Form.Group>
                        <ProgressBar className="upload-progress" now={percent} label={`${percent}%`} animated striped />;
                        {inputField.Videofile &&
                           <ReactPlayer url={inputField.Videofile} style={{ backgroundColor: '#000', margin: '20px 0px' }} width="100%" controls="true" />

                        }
                     </div> */}

                        {inputField.Video === 'Upload' ?

                           <div>
                              {!route?.state?.AIcooking && (<>
                                 <Form.Group as={Col} md="12" className="mb-3 d-flex justify-content-center" controlId="validationCustom02">
                                    <InputGroup className="mb-3">
                                       <Form.Control
                                          required
                                          type="file"
                                          placeholder="Upload File *"
                                          onChange={handleChange}
                                          accept="video/mp4,video/x-m4v,video/*"
                                          isInvalid={!!errors.Videofile}
                                       />
                                       <InputGroup.Text
                                          id="basic-addon2"
                                          className={`theme-bg ${uploading ? 'disabled' : ''}`}
                                          onClick={uploading ? null : handleUpload}
                                          disabled={uploading}
                                       >
                                          Upload
                                       </InputGroup.Text>

                                       <Form.Control.Feedback type="invalid">
                                          {errors.Videofile}
                                       </Form.Control.Feedback>
                                    </InputGroup>

                                    {/* Move Form.Control.Feedback outside of InputGroup */}

                                 </Form.Group>
                                 <ProgressBar className="upload-progress" now={percent} label={`${percent}%`} animated striped />
                              </>)}
                              {inputField?.Videofile &&
                                 <ReactPlayer url={inputField?.Videofile} style={{ backgroundColor: '#000', margin: '20px 0px' }} width="100%" controls="true" />
                              }
                           </div>

                           : inputField.Video === 'YouTube' ?
                              <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                                 <Form.Control
                                    required
                                    type="text"
                                    name="youtube_url"
                                    value={inputField.youtube_url}
                                    placeholder="Youtube Link *"
                                    onChange={inputsHandler}
                                    isInvalid={!!errors.youtube_url}

                                 />
                                 <Form.Control.Feedback type="invalid">
                                    {errors.youtube_url}
                                 </Form.Control.Feedback>
                              </Form.Group> : ""
                        }
                     </div>



                     <Ingredients

                        ingredients={ingredients}
                        setIngredients={setIngredients}
                        errors={errors}
                        setErrors={setErrors}
                     />
                     <Instructions

                        steps={steps}
                        setSteps={setSteps}
                        errors={errors}
                        setErrors={setErrors}
                     />


                     {/* <div className="form_row">
                        <div className="nutrition_data_field">

                           <div className="recipe_main-heading"><h4>Nutrition</h4></div>
                           <div className="nutrition_input_field">
                              <div className="nutrition_input_field_inner">
                                 <div className="nutrition_input_field_label">
                                    <p>Carbs</p>
                                 </div>
                                 <div className="nutrition_input_field_row">
                                    <input type="text" className="" placeholder="Amount" name="carbs_amount" value={inputField.carbs_amount} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.carbs_amount}</span>
                                    <input type="text" className="" placeholder="Units" name="carbs_units" value={inputField.carbs_units} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.carbs_units}</span>
                                 </div>
                              </div>
                              <div className="nutrition_input_field_inner">
                                 <div className="nutrition_input_field_label">
                                    <p>Energy</p>
                                 </div>
                                 <div className="nutrition_input_field_row">
                                    <input type="text" className="" placeholder="Amount" name="energy_amount" value={inputField.energy_amount} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.energy_amount}</span>
                                    <input type="text" className="" placeholder="Units" name="energy_units" value={inputField.energy_units} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.energy_units}</span>
                                 </div>
                              </div>
                              <div className="nutrition_input_field_inner">
                                 <div className="nutrition_input_field_label">
                                    <p>Fat</p>
                                 </div>
                                 <div className="nutrition_input_field_row">
                                    <input type="text" className="" placeholder="Amount" name="fat_amount" value={inputField.fat_amount} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.fat_amount}</span>
                                    <input type="text" className="" placeholder="Units" name="fat_units" value={inputField.fat_units} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.fat_units}</span>
                                 </div>
                              </div>
                              <div className="nutrition_input_field_inner">
                                 <div className="nutrition_input_field_label">
                                    <p>Protein</p>
                                 </div>
                                 <div className="nutrition_input_field_row">
                                    <input type="text" className="" placeholder="Amount" name="protein_amount" value={inputField.protein_amount} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.protein_amount}</span>
                                    <input type="text" className="" placeholder="Units" name="protein_units" value={inputField.protein_units} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.protein_units}</span>
                                 </div>
                              </div>
                              <div className="nutrition_input_field_inner">
                                 <div className="nutrition_input_field_label">
                                    <p>Sugars</p>
                                 </div>
                                 <div className="nutrition_input_field_row">
                                    <input type="text" className="" placeholder="Amount" name="sugars_amount" value={inputField.sugars_amount} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.sugars_amount}</span>
                                    <input type="text" className="" placeholder="Units" name="sugars_units" value={inputField.sugars_units} onChange={inputsHandler} />
                                    <span style={{ color: 'red' }}>{errors.sugars_units}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> */}

                     <div className="d-flex justify-content-center mt-4">
                        <button className="theme-button-secondary " style={{ marginRight: '20px' }} onClick={() => navigate('/myRecipes')} >Back</button>
                        <button className="theme-button " onClick={handleSubmit} disabled={uploading}>{action === 'add' ? 'Submit' : 'Update'}</button>

                     </div>


                  </Form>

               </Grid>
            </Grid>

         </div>

      </div>

   )
}
export default AddRecipeComponent;
