import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import { reinsertRecipe, auth, db } from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Serving_Size_Icon from './images/serving_size.png'
import Search_Icon from './images/search_icon.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import { useAuthState } from "react-firebase-hooks/auth";

import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import SelectedReci from './ReciSelected.js'
import orignal_recipie from './images/original.png'
import copy_recipie from './images/repost.png'
import retweetImg from './images/retweet.png'
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nutrition_Icon from './images/nutrition.png'
import { changeprofile } from './_actions/profileActions'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactTooltip from 'react-tooltip';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player'
import { changepost } from './_actions/postActions'
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import ProfileImage from './images/profile.png'

function GetRecis() {

  const [user] = useAuthState(auth);
  const [logged_user_id, setLoggedUserId] = useState("");
  const [RepostId, SetRespotId] = useState([]);
  const [getRepost, setRepostRecis] = useState([]);
  const [Info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Result, setResult] = useState([]);
  const [title1, setTitle] = useState('');
  const [search, setSearch] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const divStyle = {
    paddingTop: '100px'
  };
  const modalstyle = {
    display: 'none'
  };

  const opts = {
    height: '400',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: .5
      }}
    />
  );
  useEffect(() => {
    fetchUserName();
  }, []);
  useEffect(() => {
    const q = query(collection(db, 'posts'))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);


  const fetchUserName = async () => {
    try {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setInfo(doc.docs[0]?.id)
      console.log('loggedunuser', data);
      setLoggedUserId(data);
    } catch (err) {
      console.error(err);
    }
  };

  const popuphandler = (postid) => {
    console.log(postid);
    SetRespotId(postid)
    document.getElementById('myModal').style = 'display:block';
  };

  const popupClosehandler = () => {
    document.getElementById('myModal').style = 'display:none';
  };

  const reposthandler = async () => {
    console.log(logged_user_id, "logged_user_id")
    console.log('RepostId', RepostId)
    document.getElementById('myModal').style = 'display:none';

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", RepostId));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();

    setTimeout(function () {
      data.userdoc_id = Info;
      // data.user_id = data.userdoc_id;
      data.profile_pic = logged_user_id.profile_pic;
      data.repost_id = RepostId;
      reinsertRecipe(data)
    }, 1000);

    // const qs = await query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", RepostId))
    // onSnapshot(qs, (querySnapshot) => {
    //   setRepostRecis(querySnapshot.docs.map(doc => ({
    //     data: doc.data(),
    //   })))
    // })



  };
  console.log(Recis, "Recis")

  return (
    <div className='text-center'>


      <Grid container spacing={2}>
        {Recis?.reverse()?.map(({ id, data }) => (
          <Grid item sm={12} md={6} lg={4} key={id} className="recis_video_box">
            <Paper>
              <div className='recis_video_box_detail'>
                <p><b>{data.Cuisine} :</b> {data.Title}</p>
                <p><b>Author(s) ({data.author?.length})</b>
                {/*   <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data?.user_id ? data?.user_id : data.userdoc_id}`}>{data.author ? data.author[0]?.Name : ""} </Link>*/}
                  {data.author?.map((name, index) => <a key={name} href={data.author[index].profile_URL}>{data.author[index].Name}</a>)}
                </p>
                {/* <div className='reci_headerss'>
                  <p><img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> <span>Serving Size: {data.serving_size}</span>  </p>
                  <p><img src={Min_Cook_Icon} alt="Min Cook" width="20" height="20" /> <span>Min Cook: {data.min_cook}min</span></p>
                  {data.own_recipe == 1 ? (<p><img src={orignal_recipie} alt="orignal image" width="20" height="20" /> <span>Orignal</span></p>) : (<p><img src={copy_recipie} alt="orignal image" width="20" height="20" /> <span>Repost</span></p>)}
                </div> */}
                <Row className='reci-details mb-4 gy-3'>
                  <Col xl={4}>
                    <img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> <span>Serving Size: {data.serving_size}</span>
                  </Col>
                  <Col xl={4}>
                    <img src={Min_Cook_Icon} style={{ marginRight: '5px' }} alt="Min Cook" width="20" height="20" /><span>Min Cook: {data.min_cook}min</span>
                  </Col>

                  {data.own_recipe == 1 ? (<Col xl={4}><img src={orignal_recipie} alt="orignal image" width="20" height="20" /> <span>Orignal</span></Col>) : (<Col xl={4}><img src={copy_recipie} alt="orignal image" width="20" height="20" /> <span>Repost</span></Col>)}
                </Row>
                <ReactPlayer url={data.YouTube_URL} style={{ backgroundColor: '#000000' }} width="100%" controls="true" />

                <div className="mx-0 profile_list reci-tools">
                  <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}>
                    <Button as={Col} variant="primary"><img src={data.profile_pic ? data.profile_pic : ProfileImage} alt="User" width="30" height="30" /></Button>
                  </Link>
                  <Link to={`/SelectedReci/${id}`}>
                    <Button onClick={() => dispatch(changepost(data.post_id))} as={Col} variant="secondary" className="mx-2">
                      <img src={Cook_Icon} alt="Cook this Reci" width="35" height="35" />
                    </Button>
                  </Link>

                  {/* <Button as={Col} data-tip="Carbs: ${data.Nutrition.Carbs.Amount} ${data.Nutrition.Carbs.Units}" variant="primary"><img src={Nutrition_Icon} alt="See Reci Nutrition Label (Sugars, Carbs, Energy, Fats, and Proteins)" width="35" height="35" />
                    </Button>
                    <ReactTooltip /> */}

                  {/* <a href={data.Website_URL? data.Website_URL : data.YouTube_URL}>
                      <Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button>
                    </a> */}
                  {
                    data.Website_URL && <a href={data.Website_URL} target="_blank"><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button></a>
                  }
                  <Button onClick={() => logged_user_id ? popuphandler(id) : navigate('/login')} as={Col} variant="primary" >
                    <img src={copy_recipie} alt="Website" width="30" height="30" />
                  </Button>

                </div>

                <div className="mx-0 text-center" >
                  <EmailShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><EmailIcon size={32} round={true} /></EmailShareButton>
                  <FacebookShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><FacebookMessengerIcon size={32} round={true} /></FacebookShareButton>
                  <LinkedinShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                  <PinterestShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} media={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><PinterestIcon size={32} round={true} /></PinterestShareButton>
                  <RedditShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><RedditIcon size={32} round={true} /></RedditShareButton>
                  <TumblrShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
                  <TwitterShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                  <WhatsappShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div id="myModal" className="modal" style={modalstyle} >

        <div className="modal-content">
          <span className="close">&times;</span>
          <div className="modal-body">
            <p>Do you really want to repost in your profile ?</p>
          </div>
          <div className="modal-footer">
            <Button onClick={() => popupClosehandler()} as={Col} variant="primary" className='btn-no'>
              No </Button>
            <Button onClick={() => reposthandler()} as={Col} variant="primary" className='btn-yes' >
              Yes </Button>
          </div>
        </div>

      </div>

    </div>
  );
}



export default GetRecis;
