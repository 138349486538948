import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/Shelf.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import { Routes, Route, Link, } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { changeprofile } from './_actions/profileActions'
import { connect, useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import { changepost } from './_actions/postActions'
import { selectedcookbook } from './_actions/cookbookActions'
import { selectedcookbook1 } from './_actions/cookbookActions'
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { reinsertRecipe, auth, db } from './firebase';

function Cookbooks() {
  const [info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Recis1, setRecis1] = useState([]);
  const [Posts, setPosts] = useState([]);
  const [AllRecis, setAllRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);


  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_COOKBOOKS))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);


  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        id2: doc.id,
        data2: doc.data(),
      })))
    })
  }, []);


  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
    onSnapshot(q, (querySnapshot) => {
      setAllRecis(querySnapshot.docs.map(doc => ({
        postid: doc.id,
        postdata: doc.data(),
      })))
    })
  }, []);

  const divStyle = {
    paddingTop: '100px'
  };

  const opts = {
    height: '600',
    width: '90%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const searchClient = algoliasearch('SXH1UNR77P', '5cbea671c4708dfc63c7ff6845a218a2');

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: .5
      }}
    />
  );



  return (
    <div className='web-container'>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='title-2'> Cookbooks </h1>
      </div>


      {Recis.length ? 
        <Grid container spacing={{ xs: 12, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
      
          {Recis?.map(({ id, data }) => (
              <Grid item xs={12} sm={12} md={3} key={id}>
                <Paper className='cookbook_box'>
                  <p><b>{data.book_title}</b> by {data.user_id}</p>
                  <p><Link to={`/CookbookSelected/${data.myID}`} style={{ textDecoration: 'none' }}><Button onClick={() => { dispatch(selectedcookbook(data.book_id)); dispatch(changepost(data.posts[0])); }}><img src={data.icon} alt="Coobook cover" width="100%" /></Button></Link></p>
                  <div className='cookbook_tagname'>
                    {data.posts.map((name, index) =>
                      <p>{AllRecis?.map(({ postid, postdata }) => (
                        postdata.post_id == name
                          ? (<Link to={`/SelectedReci/${postdata.myID}`}>{postdata.Title}</Link>)
                          : null
                      ))}</p>
                    )}
                  </div>

                  <p><b>Members: </b>
                    {data.members_list.map((memberid, index) =>
                      <span>{Users?.map(({ id2, data2 }) => (

                        data2.myID == memberid
                          ? (<Link to={`/ProfileSelected/${data2.myID}`}>{data2.user_id} </Link>)
                          : null
                      ))}</span>
                    )}

                  </p>

                  {/* <p><b>Members: </b>
          
          {data.members.map((membername,index) => 
          <Link style={{textDecoration: 'none'}} to={`/ProfileSelected/${membername}`}>{membername} </Link>  
          )}

          </p> */}
                  {/* <p><b>Members: </b> 
      
          <Link style={{textDecoration: 'none'}} onClick={() => dispatch(changeprofile(data.members[0]))}  to={`/ProfileSelected/${data.user_id}`}>{data.members[0]}</Link>
          <Link style={{textDecoration: 'none'}} onClick={() => dispatch(changeprofile(data.members[1]))} to={`/ProfileSelected/${data.members[1]}`}>{data.members[1]}</Link>
          
          <Link style={{textDecoration: 'none'}} onClick={() => dispatch(changeprofile(data.members[2]))} to={`/ProfileSelected/${data.members[2]}`}>{data.members[2]}</Link>
            <Link style={{textDecoration: 'none'}} onClick={() => dispatch(changeprofile(data.members[3]))} to="/ProfileSelected">{data.members[3]}</Link></p> */}
                  <Link to={`/CookbookSelected/${data.myID}`} style={{ textDecoration: 'none' }}><Button className='button_cookbook' style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'red',
                    borderRadius: 50,
                    color: 'white'
                  }} onClick={() => { dispatch(selectedcookbook(data.book_id)); dispatch(changepost(data.posts[0])); }}><b>See the {data.book_title} CookBook</b></Button></Link>
                </Paper>
              </Grid>
              
          ))}
        </Grid>
        
        : <div className="text-center" >No records found</div> }
      
    </div>
  );
}

export default Cookbooks;
