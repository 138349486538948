import { createStore, combineReducers } from 'redux';
import cookbookReducers from '../_reducers/cookbookReducers.js';
import postReducers from '../_reducers/postReducers.js';
import userReducers from '../_reducers/userReducers.js';
import profileReducers from '../_reducers/profileReducers.js';

const rootReducer = combineReducers(
{
  cookbookReducers: cookbookReducers,
  postReducers: postReducers,
  userReducers: userReducers,
  profileReducers: profileReducers
  }
);

const store = createStore(rootReducer)
console.log(store.getState())


export default store;
