import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import {db} from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import Health_Icon from './images/Health.png'
import LiveStream_Icon from './images/LiveStream.png'
import { Routes, Route, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles

function CalendarSelected() {

  const events = {
      '01-11-2021': [
          {
          id: 1,
          startAt: '2021-11-21T18:00:00.000Z',
          endAt: '2021-11-21T19:00:00.000Z',
          timezoneStartAt: 'Europe/Berlin', // optional
          summary: 'test',
          color: 'blue',
          }
      ],
      '21-11-2021': [
          {
          id: 2,
          startAt: '2021-11-21T18:00:00.000Z',
          endAt: '2021-11-21T19:00:00.000Z',
          timezoneStartAt: 'Europe/Berlin', // optional
          summary: 'test',
          color: 'blue',
          }
      ]
  }


    return (
      <>
        <Kalend
          events={[]}
          initialDate={new Date().toISOString()}
          hourHeight={60}
          initialView={CalendarView.WEEK}
          disabledViews={[CalendarView.DAY]}
          timeFormat={'24'}
          weekDayStart={'Monday'}
          calendarIDsHidden={['work']}
          language={'en'}
        />
      </>
    );
  }

export default CalendarSelected;
