

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import Googleicon from './images/googleicon.png'
import Gmailicon from './images/gmail.png'
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle, db,
  signUpWithGoogle
} from "./firebase";
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { user_types } from "./utils/dataUtils";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  updateDoc,
  where,
  addDoc,
  doc

} from "firebase/firestore";

function SignUp() {

  const [user, loading, error] = useAuthState(auth);
  const [GSignup, setGSignup] = useState(false);
  const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}');


  const [inputField, setInputField] = useState({
    name: '',
    email: '',
    password: '',
    userType: '',
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();


  useEffect(async () => {
    if (loading) return;

    if (user) {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot?.empty) {
        navigate("/dashboard");
        setTimeout(() => {
          window.location.reload();
        }, 500)
      }
    }
  }, [user, loading]);

  const inputsHandler = (e) => {
    e.preventDefault();
    setInputField({ ...inputField, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const findErrors = () => {
    const { name, email, password, userType } = inputField;

    const newErrors = {}

    // Regular expressions for email and strong password validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

    if (!userType || userType === '') newErrors.userType = 'User type is required!';

    if (!GSignup) {
      if (!name || name === '') newErrors.name = 'Full name is required!';
      if (!email || email === '') newErrors.email = 'Email is required!';
      else if (!emailRegex.test(email)) newErrors.email = 'Email not valid!';
      if (!password || password === '') {
        newErrors.password = 'Password is required!';
      } else if (!strongPasswordRegex.test(password)) {
        newErrors.password = 'Password must be at least 8 characters long and include at least one uppercase letter and one special character!';
      }
    }
    return newErrors;
  }



  const handleSubmit = e => {
    e.preventDefault();


    const newErrors = findErrors();
    console.log(newErrors, "newErrors");


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      registerWithEmailAndPassword(inputField);
      setTimeout(() => {
        if (inputField.userType !== 'Chef' && user) {
          navigate("/dashboard");
          window.location.reload();
        }
      }, 1000)
    }
  }

  const googleSignup = e => {
    e.preventDefault();

    const newErrors = findErrors();
    console.log(newErrors, "newErrors");

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      signUpWithGoogle(inputField);
    }
  }



  return (
    <div className="login">
      <div className="login__container">
        <div className="form_head">
          <h2>Sign Up</h2>
          <p> Already have an account? <Link to="/login">Login</Link> now.</p>
        </div>

        <Form className={`${GSignup ? 'd-none' : ''}`} noValidate onSubmit={handleSubmit}>
          <Row>

            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Control
                className="theme-input"
                required
                type="text"
                name="name"
                value={inputField.name}
                placeholder="Full Name *"
                onChange={inputsHandler}
                isInvalid={!!errors.name}

              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3" controlId="userType" >
              <Form.Select
                className="theme-input"
                name="userType"
                value={inputField.userType}
                onChange={inputsHandler}
                isInvalid={!!errors.userType}
                required
              >
                <option value="" disabled >Select User Type *</option>
                {
                  user_types.map(data => (
                    <option value={data.value}>{data.label}</option>
                  ))
                }


              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.userType}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Control
                required
                className="theme-input"
                type="email"
                name="email"
                value={inputField.email}
                placeholder="E-mail Address *"
                onChange={inputsHandler}
                isInvalid={!!errors.email}

              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
              <Form.Control
                required
                className="theme-input"
                type="password"
                name="password"
                value={inputField.password}
                placeholder="Password *"
                onChange={inputsHandler}
                isInvalid={!!errors.password}

              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Col className="mb-4">
              <button className="login__btn" >
                Sign Up
              </button>
            </Col>





          </Row>
        </Form>

        <div className={`${GSignup ? 'd-none' : ''}`}>
          <div className="striped">
            <span className="striped-line"></span>
            <span className="striped-text">Or</span>
            <span className="striped-line"></span>
          </div>
          <Col className="mb-4">
            <button className=" login__google" onClick={() => setGSignup(true)}>
              <img src={Googleicon} />
              Sign Up with Google
            </button>
          </Col>
        </div>

        <Form className={`${!GSignup ? 'd-none' : ''}`} noValidate onSubmit={googleSignup}>
          <Row>



            <Form.Group as={Col} md="12" className="mb-3" controlId="userType" >
              <Form.Select
                className="theme-input"
                name="userType"
                value={inputField.userType}
                onChange={inputsHandler}
                isInvalid={!!errors.userType}
                required
              >
                <option value="" disabled >Select User Type *</option>
                {
                  user_types.map(data => (
                    <option value={data.value}>{data.label}</option>
                  ))
                }


              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.userType}
              </Form.Control.Feedback>
            </Form.Group>



            <Col className="mb-4">
              <button className="login__btn" >
                Sign Up With Google
              </button>
            </Col>

          </Row>
        </Form>

        <div className={`${!GSignup ? 'd-none' : ''}`}>
          <div className="striped">
            <span className="striped-line"></span>
            <span className="striped-text">Or</span>
            <span className="striped-line"></span>
          </div>
          <Col className="mb-4">
            <button className="login__google" onClick={() => setGSignup(false)}>
              <img src={Gmailicon} className="mr-4" />
              Sign Up with Email
            </button>
          </Col>
        </div>



      </div>
    </div>

  );
}

export default SignUp;
